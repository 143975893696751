<template>
  <div class="w-100 p-0 overflow-hidden">
    <b-table
      class="position-relative"
      :items="members"
      responsive
      :small="isSmall"
      :fields="fields"
      :busy="busy"
      per-page="15"
      primary-key="id"
      show-empty
      :table-class="members.length < 5 ? 'mb-5' : ''"
    >
      <!-- Column: Member -->
      <template #cell(name)="{ item }">
        <b-media class="d-flex align-items-center pl-1 ml-25 mt-25">
          <template>
            <b-link :to="getMemberLocation(item)" class="text-dark d-flex align-items-center">
              <div>
                <user-avatar :user="item" size="32" variant="light-primary" />
              </div>
              <div class="ml-1">
                <p v-if="item.name" class="font-weight-extrabold mb-0">
                  {{ `${item.name} ${item.surname}` }}
                  <span v-if="memberIsOwner(item)">
                    <feather-icon icon="StarIcon" size="20" title="Backoffice owner" style="padding-left: 5px; color: rgb(255, 183, 0);" />
                  </span>
                </p>
                <span v-else class="text-muted">---</span>
              </div>
            </b-link>
          </template>
        </b-media>
      </template>

      <!-- Column: Headline -->
      <!--template #cell(headline)="{ item }">
        <p v-if="item.headline" class="mb-0 pl-25 ml-1 text-capitalize truncated-text mt-75">
          {{ item.headline }}
        </p>
        <p v-else class="text-muted mb-0 pl-25 ml-1 mt-75">
          ---
        </p>
      </template-->

      <!-- Column: Emails -->
      <template #cell(email)="{ item }">
        <p class="align-text-top mb-0 pl-25 ml-1 mt-75">
          {{ item.email }}
        </p>
      </template>

      <!-- Column: Roles -->
      <template #cell(roles)="{ item }">
        <div v-if="item.roles && item.roles.length > 0" class="mt-75">
          <p v-for="(role, i) in item.roles.filter(role => { if(role.namespace === 'backoffice'){return role; }})" :key="i" class="align-text-top mb-0 pl-25 ml-1 mt-25">
            {{ role.name }}
          </p>
        </div>
      </template>

      <!-- Column: Updated date time -->
      <template #cell(updatedTime)="{ item }">
        <p class="align-text-top text-capitalize mb-0 pl-25 ml-1 mt-75">
          {{ getDate(item) }}
        </p>
      </template>

      <!-- Column: Actions -->
      <template #cell(actions)="data">
        <b-dropdown
          id="dropdown-dropleft"
          variant="link"
          no-caret
          :dropup="members.length < 2"
          :dropleft="members.length <= 2"
          class="ml-2"
          @shown="data.item.status === 3 ? getAttendance(data.item) : ''"
        >
          <template #button-content>
            <feather-icon icon="MoreVerticalIcon" size="16" />
          </template>
          <!-- Dropdown menu header -->
          <b-dropdown-header id="dropdown-header-label" class="bg-light">
            <p v-if="data.item.email" class="font-weight-extrabold mb-0">
              {{ `${data.item.name} ${data.item.surname}` }} <span v-if="data.item.name">(</span>{{ data.item.email }}<span v-if="data.item.name">)</span>
            </p>
          </b-dropdown-header>
          <b-dropdown-item v-if="isOwner" @click="handleOpenRemoveDefinitelyModal(data.item)">
            <feather-icon icon="Trash2Icon" class="mr-50" />
            {{ $t('organizations.backoffice.settings.members.remove-member') }}
          </b-dropdown-item>
          <b-dropdown-item @click="roleEdit(data.item)">
            <feather-icon icon="UnlockIcon" class="mr-50" />
            {{ $t('members.roles.edit') }}
          </b-dropdown-item>
        </b-dropdown>
      </template>
      <template #table-busy>
        <div class="text-center p-5">
          <b-spinner
            size="2rem"
            type="grow"
            class="d-block m-auto m-1"
            variant="primary"
            label="Loading..."
          />
        </div>
      </template>
      <!-- Empty table -->
      <template #empty>
        <div class="horizontal-placeholder d-block">
          <div v-if="search && members && members.length === 0">
            <div>
              <img :src="membersPlaceholder" class="d-block">
            </div>
            <div>
              <p class="text-primary text-center">
                {{ $t('available.message', { itemName: $t('members.title') }) }}
              </p>
            </div>
          </div>
          <b-spinner
            v-else
            size="2rem"
            small
            type="grow"
            class="d-block m-auto m-1"
            variant="primary"
            label="Loading..."
          />
        </div>
      </template>
    </b-table>

    <!-- Staff Modal -->
    <b-modal
      id="staff-modal"
      v-model="isStaffModalOpened"
      :title="$t('backoffice.members.disable-staff')"
      :ok-title="$t('form.actions.save')"
      size="sm"
      centered
      hide-footer
    >
      <b-form-group>
        <p class="font-weight-bold mt-1 ">
          {{ $t('backoffice.members.modals.staff-disable-message') }}
        </p>
        <p class="font-weight-bolder mt-1 text-center">
          {{ currentMember.name }} {{ currentMember.surname }} ({{ currentMember.email }})
        </p>
        <div class="text-center mb-2">
          <b-button
            class="font-weight-bold"
            variant="danger"
            @click="toggleIsStaff()"
          >
            {{ $t('backoffice.members.disable-staff') }}
          </b-button>
        </div>
      </b-form-group>
    </b-modal>
    
    <!-- Info: Can't validate -->
    <b-modal
      id="staff-modal"
      v-model="isInfoModalOpened"
      :title="$t('backoffice.members.modals.info-validate')"
      :ok-title="$t('form.actions.save')"
      size="sm"
      centered
      hide-footer
    >
      <feather-icon size="30" icon="AlertTriangleIcon" class="d-block mx-auto text-danger mb-1" />
      <h5 class="mb-25 px-2 text-center">
        {{ $t('backoffice.members.modals.info-validate') }}.
      </h5>
      <h5 class="mb-2 px-2 text-center">
        {{ $t('backoffice.members.modals.info-message') }}.
      </h5>
      <div class="text-center mb-1">
        <b-button
          variant="primary"
          class="mt-25"
          @click="isInfoModalOpened = false"
        >
          {{ $t('form.actions.ok') }}
        </b-button>
      </div>
    </b-modal>

    <!-- Can't disable staff if member is Owner -->
    <b-modal
      id="staff-modal-owner"
      v-model="isInfoModalOwnerOpened"
      :title="$t('backoffice.members.modals.info-owner')"
      :ok-title="$t('form.actions.save')"
      size="sm"
      centered
      hide-footer
    >
      <feather-icon size="30" icon="AlertTriangleIcon" class="d-block mx-auto text-danger mb-1" />
      <h5 class="mb-2 px-2 text-center">
        {{ $t('backoffice.members.modals.info-owner-message') }}.
      </h5>
      <div class="text-center mb-1">
        <b-button
          variant="primary"
          class="mt-25"
          @click="isInfoModalOwnerOpened = false"
        >
          {{ $t('form.actions.ok') }}
        </b-button>
      </div>
    </b-modal>


    <!-- Remove all -->
    <b-modal
      id="revove-all-modal"
      v-model="isRemoveAllModalOpened"
      :title="$t('backoffice.members.remove-all')"
      :ok-title="$t('form.actions.save')"
      size="sm"
      hide-footer
      @ok="handleDisableAll()"
    >
      <b-form-group>
        <p class="font-weight-bold mt-1  text-center">
          {{ $t('backoffice.members.remove-message') }}
        </p>
        <p class="font-weight-bolder mt-1 text-center">
          {{ currentMember.name }} {{ currentMember.surname }} ({{ currentMember.email }})
        </p>
        <div class="text-center mb-2">
          <b-button class="font-weight-bold" variant="danger" @click="handleDisableAll()">
            {{ $t('backoffice.members.disable-all') }}
          </b-button>
        </div>
      </b-form-group>
    </b-modal>

    <!-- Remove definitely -->
    <b-modal
      id="revove-all-modal"
      v-model="isRemoveDefinitelyModalOpened"
      :title="$t('organizations.backoffice.settings.members.remove-member')"
      :ok-title="$t('form.actions.save')"
      :size="isMedium ? 'xl' : 'lg'"
      hide-footer
    >
      <b-form-group>
        <p class="font-weight-bold mt-1 mb-0 text-center">
          {{ $t('organizations.backoffice.settings.members.remove-message-bo') }}
        </p>
        <p class="font-weight-bold text-center">
          {{ $t('organizations.backoffice.settings.members.explanation-message-bo') }}
        </p>
        <p class="font-weight-bolder mt-1 text-center">
          {{ currentMember.name }} {{ currentMember.surname }} ({{ currentMember.email }})
        </p>
        <div class="text-center mb-2">
          <b-button class="font-weight-bold" variant="danger" @click="handleDisableDefinitely()">
            {{ $t('organizations.backoffice.settings.members.remove-member') }}
          </b-button>
        </div>
      </b-form-group>
    </b-modal>

    <!-- Roles -->
    <b-modal
      v-model="isRolesModalVisible"
      :title="$t('members.roles.edit')"
      :ok-title="$t('form.actions.save')"
      no-close-on-backdrop
      @ok="setRoles"
    >
      <b-form-group>
        <label for="profile-role"> {{ $t('backoffice.roles.backoffice-roles') }}</label>
        <v-select
          id="type"
          v-model="rolesBackofficeSelected"
          multiple
          :placeholder="$t('form.select-placeholder')"
          :options="backofficeRolesOptions"
          label="name"
        />
      </b-form-group>
    </b-modal>

    <!-- Error modal -->
    <error-modal
      v-ripple.400="'rgba(234, 84, 85, 0.15)'"
      :title="$t('backoffice.members.messages.error-admin')"
      :subtitle="$t('appsumo.errors-modal.subtitle')"
    />
  </div>
</template>

<script>
import Teleport from 'vue2-teleport';
import { translateTranslationTable } from '@/@core/libs/i18n/utils';
import { ENABLED_APPS_GETTERS } from '@/store/enabled-apps/enabled-apps-store-constants';
import { getImageResource } from '@/@core/utils/image-utils';
import UserAvatar from '@core/components/user/UserAvatar.vue';
import { CHAT_ACTIONS } from '@/views/apps/chat/constants/chat-store-constants';
import Service from '@/config/service-identifiers';
import MembersPlaceholder from '@/assets/images/placeholders/light/members.svg';
import RequestMeetingModal from '@/views/apps/member/modals/RequestMeetingModal.vue';
import ToastNotificationsMixin from '@core/mixins/toast-notifications/ToastNotificationsMixin';
import vSelect from 'vue-select';
import MedellinCertificate from '@core/components/members/CertificateMedellin.vue';
import Certificate from '@core/components/members/Certificate.vue';
import ErrorModal from '@core/components/modal/ErrorModal.vue';
import MemberAddConnectionsModal from '../../../../views/apps/member/modals/MemberAddConnectionsModal.vue';

export default {
  name: 'StaffTable',
  components: {
    Teleport,
    MemberAddConnectionsModal,
    UserAvatar,
    RequestMeetingModal,
    vSelect,
    MedellinCertificate,
    Certificate,
    ErrorModal,
  },
  mixins: [ToastNotificationsMixin],
  props: {
    members: {
      type: [Array, Function],
      default: () => [],
    },
    busy: Boolean,
    search: Boolean,
  },
  data() {
    return {
      isSmall: false,
      isMedium: false,
      memberForMeeting: null,
      isRequestMeetingVisible: false,
      isStaff: true,
      isMember: false,
      isStaffModalOpened: false,
      isMemberModalOpened: false,
      isStatusModalOpened: false,
      isVerified: false,
      isRemoveAllModalOpened: false,
      currentMember: {},
      enableStatus: '',
      isRemoveDefinitelyModalOpened: false,
      isRemoveAttendanceModalOpened: false,
      isRequestedModalOpened: false,
      answerRequest: '',
      selectedMember: null,
      isConnectionModalVisible: false,
      isOnboardingModalOpened: false,
      isLoading: false,
      isRolesModalVisible: false,
      rolesSelected: [],
      rolesBackofficeSelected: [],
      backofficeRolesOptions: [],
      mainMembers: [],
      organizations: [],
      organizationsMember: [],
      isOrganizationsModalVisible: false,
      isTokenModalVisible: false,
      token: '',
      isCopied: false,
      isAttendanceLoading: true,
      isRegisterModalVisible: false,
      isInfoModalOpened: false,
      isCertificateAttendanceModalOpened: false,
      fields: [],
      isInfoModalOwnerOpened: false,
      currentCompletesPage: 1,
      currentCompletableElementsPage: 1,
        bars: [
          { variant: 'success', value: 75 },
          { variant: 'warning', value: 50 },
          { variant: 'danger', value: 25 },
        ],
    };
  },
  computed: {
    locale() {
      return this.$store.state.locale.currentLocale;
    },
    isMedellin() {
      return this.currentCollective === 'e1326b70-dc13-11ec-9877-3b619755c76d' || this.mainCollective === 'e1326b70-dc13-11ec-9877-3b619755c76d';
    },
    currentCollective() {
      return this.$store.getters.currentCollective.key;
    },
    currentCollectiveType() {
      return this.$store.getters.currentCollective.mainType;
    },
    mainCollective() {
      return this.$store.getters.mainCollective.key;
    },
    attendance() {
      return this.$store.getters.attendance.paginated[1];
    },
    membersError() {
      return this.$store.getters.membersError;
    },
    loggedMember() {
      return this.$store.getters.loggedMember;
    },
    hasMain() {
      return this.$store.getters.mainCollective?.slug;
    },
    communityRoles() {
      return this.$store.getters.roles?.unpaginated;
    },
    memberRoles() {
      return this.$store.getters.memberRoles?.unpaginated;
    },
    requestedOptions() {
      return [
        { name: this.$t('backoffice.members.modals.accept-request'), value: true },
        { name: this.$t('backoffice.members.modals.reject-request'), value: false },
      ];
    },
    hasChatIndividualAddOn() {
      if (this.$store.getters[ENABLED_APPS_GETTERS.enabledApps].chatIndividual != null) {
        return this.$store.getters[ENABLED_APPS_GETTERS.enabledApps].chatIndividual.isActive;
      }
      return false;
    },
    hasMeetingsSlotsAddOn() {
      return this.$store.getters[ENABLED_APPS_GETTERS.enabledApps].meetingsSlots;
    },
    hasMeetingAddOn() {
      return this.$store.getters[ENABLED_APPS_GETTERS.enabledApps].meetings;
    },
    currentUser() {
      return this.$store.getters.loggedUser;
    },
    membersPlaceholder() {
      return MembersPlaceholder;
    },
    isNecessary() {
      return this.currentCollectiveType === 'Event' || this.currentCollectiveType === 'Course' || this.currentCollectiveType === 'Challenge';
    },
    isOwner() {
      return this.$store.getters.loggedMemberRoles?.find(({ tagName }) => tagName === 'owner');
    },
    statusMember() {
      return {
        0: this.$t('backoffice.members.pending'),
        1: this.$t('backoffice.members.requested-join'),
        2: this.$t('backoffice.members.rejected'),
        3: this.$t('backoffice.members.confirmed'),
        4: this.$t('backoffice.members.rejected-admin'),
        5: this.$t('backoffice.members.rejected-user'),
        6: this.$t('backoffice.members.left'),
        7: this.$t('backoffice.members.invited'),
        8: this.$t('backoffice.members.waiting-form'),
        81: this.$t('backoffice.members.waiting-form'),
      };
    },
    optionsEnableMember() {
      return {
        0: this.$t('backoffice.members.pending'),
        1: this.$t('backoffice.members.requested-join'),
        3: this.$t('backoffice.members.confirmed'),
        7: this.$t('backoffice.members.invited'),
      };
    },
    onboardingAnswers() {
      if (this.$store.getters.onboardingAnswers?.unpaginated?.length > 0) {
        return this.$store.getters.onboardingAnswers?.unpaginated[0].answers;
      }
      return [];
    },
    onboardingAnswers2() {
      const formAnswers = this.onboardingAnswers;
      console.log('formAnswers', formAnswers);
      if(formAnswers.length > 0){
        return formAnswers.answers;
      }
      return [];
    },
    currentCommunity() {
      return this.$store.getters.currentCollective;
    },
    hasCommunityBypass() {
      return this.hasMain && this.currentCommunity.bypass;
    },
    collective() {
      return this.$store.getters.currentCollective;
    },
    completableElementsLastPage() {
      return this.$store.getters.completableElements.meta.last_page;
    },
    communityCompletableElements() {
      return this.$store.getters.completableElements.paginated[this.currentCompletableElementsPage] || [];
    },
    totalCommunityCompletableElements() {
      return this.$store.getters.completableElements?.meta?.total;
    },
    completesLastPage() {
      return this.$store.getters.completedItems.meta.last_page;
    },
    communityCompletes() {
      return this.$store.getters.completedItems.paginated[this.currentCompletesPage] || [];
    },
    totalCommunityCompletes() {
      return this.$store.getters.completedItems?.meta?.total;
    },
  },
  async created() {
    console.log('this.type', this.type)
    this.fields = [
      {
        key: 'name',
        label: this.$t('backoffice.members.fullname'),
        sortable: true,
      },
      {
        key: 'email',
        label: this.$t('backoffice.settings.email-label'),
        sortable: true,
      },
      {
        key: 'roles',
        label: this.$t('members.roles.edit'),
        sortable: true,
      },
      {
        key: 'updatedTime',
        label: this.$t('backoffice.members.update'),
        sortable: true,
      },
      {
        key: 'actions',
        label: this.$t('backoffice.members.actions'),
        sortable: false,
      },
    ];
    window.addEventListener('resize', this.handleResize);
    this.handleResize();
    if (this.hasMain) {
      await this.getLoggedMember();
    }
  },
  destroyed() {
    window.removeEventListener('resize', this.handleResize);
  },
  watch: {
    //
  },
  methods: {
    memberIsOwner(member) {
      return member?.roles?.filter((item) => item.name === 'Owner').length > 0;
    },
    getValidationDate() {
      return new Date(this.attendance[0]?.attendanceDate).toLocaleString();
    },
    handleResetPasswordTokenClipboardCopied() {
      this.isCopied = true;
      return navigator.clipboard.writeText(this.token.passwordToken);
    },
    handleRegisterTokenClipboardCopied() {
      this.isCopied = true;
      return navigator.clipboard.writeText(this.token.registerToken);
    },
    async getLoggedMember() {
      const response = await this.$store.$service[Service.BackendClient].get('/communityMembers', {
        params: {
          communitySlug: this.hasMain,
          isForBackoffice: 1,
        },
      });
      this.mainMembers = response.data.data;
      return response;
    },
    handleSidebarChange(isVisible, user) {
      if (isVisible) {
        this.$store.dispatch(CHAT_ACTIONS.openIndividualChat, { user });
      } else {
        this.$store.dispatch(CHAT_ACTIONS.closeIndividualChat);
      }
    },
    getMemberLocation(user) {
      if (!user.username) {
        return null;
      }

      return {
        name: 'backoffice-members-details',
        params: { username: user.username, communityId: this.$route.params.communityId },
      };
    },
    handleResize() {
      this.isSmall = window.innerWidth < 400;
      this.isMedium = window.innerWidth < 1024;
    },
    getImageResource,
    getOrganizations(item) {
      return item?.organizations.map((organization) => organization.name);
    },
    getDate(item) {
      const d = new Date(item.updatedTime);
      return d.toLocaleString('en-GB');
    },
    getStatus(member) {
      return this.statusMember[member];
    },
    getColor(member) {
      const colorMember = {
        0: 'light',
        1: 'light-warning',
        2: 'warning',
        3: 'light-success',
        4: 'light-danger',
        5: 'light-dark',
        6: 'secondary',
        7: 'light-info',
        8: 'info',
        81: 'dark',
      };
      return colorMember[member];
    },
    handleOpenStaffModal(item) {
      this.isStaffModalOpened = true;
      this.currentMember = item;
    },
    handleOpenInfoModal() {
      this.isInfoModalOpened = true;
    },
    handleOpenRemoveAllModal(item) {
      this.isRemoveAllModalOpened = true;
      this.currentMember = item;
    },
    async handleDisableAll() {
      this.isRemoveAllModalOpened = false;
      let member = {};
      try {
        member = { ...this.members.find((item) => item.key === this.currentMember.key) };
        member.isStaff = false;
        member.isMember = false;
        await this.$store.dispatch('editItem', {
          item: {
            itemType: 'backoffice/disableMember',
            customName: 'membersBO',
            requestConfig: {
              memberKey: this.currentMember.key,
            },
          },
        });
        member = this.members.find((item) => item.key === this.currentMember.key);
        member.isStaff = false;
        member.isMember = false;
        this.currentMember = {};
        this.notifySuccess(this.$t('backoffice.members.modals.success-desable-all'));
      } catch {
        this.notifySuccess(this.$t('backoffice.members.modals.error-disable-all'));
      }
    },
    handleOpenRemoveDefinitelyModal(item) {
      this.isRemoveDefinitelyModalOpened = true;
      this.currentMember = item;
    },
    async handleDisableDefinitely() {
      this.isRemoveDefinitelyModalOpened = false;
      try {
        this.members = this.members.filter((item) => item.key !== this.currentMember.key);
        await this.$store.dispatch('postItem', {
          type: '/deleteCommunityMember',
          requestConfig: {
            memberKey: this.currentMember.key,
          },
        });
        this.currentMember = {};
        this.notifySuccess(this.$t('organizations.backoffice.settings.members.alert-messages.success'));
      } catch {
        this.notifyError(this.$t('organizations.backoffice.settings.members.alert-messages.error'));
      }
    },
    async getRoles(page = 1) {
      let space = 'backoffice';
      const response = await this.$store.dispatch('getRoles', {
        page,
        perPage: 15,
        namespace: 'backoffice',
      });
      this.backofficeRolesOptions = response.data.filter(({ namespace }) => namespace === 'backoffice');
    },
    async setRoles() {
      // Removed roles
      const removedRoles = [];
      this.currentMember.roles.forEach((item) => (!this.rolesSelected.includes(item) && !this.rolesBackofficeSelected.includes(item) ? removedRoles.push(item.key) : null));
      // Assigned Roles
      const keys = [];
      const allRoles = [...this.rolesBackofficeSelected, ...this.rolesSelected];
      this.communityRoles.map(({ key }) => {
        allRoles.map((item) => (key === item.key ? keys.push(key) : null));
      });
      try {
        await this.$store.dispatch('setRoles', {
          memberKey: this.currentMember.key,
          roleKeys: keys,
          deleteRoleKeys: removedRoles,
        });
        this.notifySuccess(this.$t('members.roles.messages.success'));
        this.$emit('roles', true);
      } catch {
        this.notifyError(this.$t('members.roles.messages.error'));
      }
    },
    roleEdit(item) {
      this.currentMember = item;
      this.rolesBackofficeSelected = this.currentMember.roles.filter((item) => item.namespace === 'backoffice');

      this.isRolesModalVisible = true;
      this.getRoles();
    },
    async tokenEdit(item) {
      this.currentMember = item;
      await this.getEmailData();
      this.isTokenModalVisible = true;
    },
    async registerEmails(item) {
      this.currentMember = item;
      await this.getEmailData();
      this.isRegisterModalVisible = true;
    },
    async getEmailData() {
      try {
        const response = await this.$store.dispatch('getItems', {
          itemType: 'communityMember/emailData',
          customName: 'tokenVerification',
          forceAPICall: true,
          requestConfig: {
            memberKey: this.currentMember.key,
          },
        });
        this.token = response;
      } catch {}
    },
    async sendRegistrationEmail(type) {
      try {
        await this.$store.$service[Service.BackendClient].post('/backoffice/resendEmail', {
          communitySlug: this.currentCommunity.slug,
          typeOfEmail: type,
          memberKey: this.currentMember.key,
        });
        this.notifySuccess(this.$t(`share.messages.${type}.success`));
        this.getEmailData();
      } catch {
        this.notifyError(this.$t(`share.messages.${type}.error`));
      }
    },
    organizationLocation(organization) {
      if (this.$route.path.includes('backoffice')) {
        const route = this.$router.resolve({ name: 'Resumen', params: { communityId: organization.slug } });
        window.open(route.href, '_blank');
        return;
      }
      return this.$router.replace({
        name: 'organization',
        params: {
          slug: organization.slug,
          communityId: this.$route.params.communityId,
        },
      });
    },
    showAllOrganizations(item) {
      this.organizationsMember = this.memberOrganizations(item);
      this.currentMember = item;
      this.isOrganizationsModalVisible = true;
    },
    async validateAttendance(item) {
      this.currentMember = item;
      try {
        await this.$store.dispatch('postItem', {
          type: '/communities/attendance/onsite',
          requestConfig: {
            memberKey: this.currentMember.key,
          },
        });
        this.notifySuccess(this.$t('backoffice.members.success'));
        await this.getAttendance(item);
        this.currentMember.attendOnsite = true;
      } catch {
        this.notifyError(this.$t('backoffice.members.messages.import-error'));
      }
    },
    handleUnattendance(item) {
      this.currentMember = item;
      this.isRemoveAttendanceModalOpened = true;
    },
    handleCertificate(item) {
      this.currentMember = item;
      this.isCertificateAttendanceModalOpened = true;
    },
    async handleRemoveAttendance() {
      try {
        await this.$store.dispatch('postItem', {
          type: '/backoffice/cancelOnsiteAttendance',
          requestConfig: {
            memberKey: this.currentMember.key,
          },
        });
        this.currentMember = {};
        this.$emit('roles', true);
        this.notifySuccess(this.$t('organizations.backoffice.settings.attendance.alert-messages.success'));
      } catch {
        this.notifyError(this.$t('organizations.backoffice.settings.attendance.alert-messages.error'));
      }
      this.isRemoveAttendanceModalOpened = false;
    },
    translateTranslationTable,
  },
};
</script>
<style lang="scss" scoped>
.truncated-text {
  white-space: nowrap;
  flex: 1;
  text-overflow: ellipsis;
  overflow: hidden;
  max-width: 250px;
  min-width: 0;
}
.check-button {
  cursor: pointer !important;
}
.uppercase {
  text-transform: uppercase;
}
</style>
