<template>
  <b-modal
    id="new-member2"
    :title="$t('backoffice.members.invite-member')"
    :ok-title="$t('form.actions.save')"
    size="lg"
    ok-only
    :hide-footer="!formIsVisible"
    :ok-disabled="!isValid && csvFile === null"
    @ok="inviteMember()"
    @hidden="reset()"
  >
    <div v-if="currentCommunity.isEvent">
      <p
        v-if="hasOccupationAdded"
        class="mb-25"
        style="font-size: 0.8rem; font-weight: 600"
      >
        {{
          `${$t("events.details.occupation")} / ${$t(
            "events.details.capacity"
          )}`.toUpperCase()
        }}
      </p>
      <b-row v-if="currentCommunity.occupation" class="mb-1">
        <b-col
          :cols="currentCommunity.modality === 'hybrid' ? 5 : 12"
          v-if="
            currentCommunity.occupation.online ||
            currentCommunity.occupation['on-site']
          "
          class="text-secondary"
        >
          <feather-icon icon="UsersIcon" class="mr-50" />
          {{ getCapacity("online") }}
          <span v-if="currentCommunity.modality === 'hybrid'">(Online)</span>
        </b-col>
        <b-col
          :cols="currentCommunity.modality === 'hybrid' ? 5 : 12"
          v-if="
            (currentCommunity.occupation.online ||
              currentCommunity.occupation['on-site']) &&
            currentCommunity.modality === 'hybrid'
          "
          class="text-secondary"
        >
          <feather-icon icon="UsersIcon" class="mr-50" />
          {{ getCapacity("on-site") }} (On-site)
        </b-col>
      </b-row>
    </div>
    <!-- End - if is event -->

    <div v-if="formIsVisible">
      <!-- Email -->
      <b-form-group class="flex-grow-1">
        <label for="email-name" class="font-weight-bold">
          {{ $t("backoffice.settings.email-label") }}*
        </label>
        <b-form-input
          id="email-name"
          v-model="invited.email"
          type="email"
          :placeholder="$t('form.type-placeholder')"
          :state="isValid"
          @input="emailValidator(invited.email)"
        />
      </b-form-group>

      <!-- Name and Surname -->
      <b-row>
        <b-col>
          <!-- Name -->
          <b-form-group class="flex-grow-1">
            <label for="invited-name" class="font-weight-bold">
              {{ $t("backoffice.roles.name") }}
            </label>
            <b-form-input
              id="invited-name"
              v-model="invited.name"
              type="text"
              :placeholder="$t('form.type-placeholder')"
            />
          </b-form-group>
        </b-col>
        <b-col>
          <!-- Surname -->
          <b-form-group class="flex-grow-1">
            <label for="invited-surname" class="font-weight-bold">
              {{ $t("backoffice.owner.surname") }}
            </label>
            <b-form-input
              id="invited-surname"
              v-model="invited.surname"
              type="text"
              :placeholder="$t('form.type-placeholder')"
            />
          </b-form-group>
        </b-col>
      </b-row>

      <!-- Bypass -->
      <div class="d-flex flex-wrap justify-content-between">
        <b-form-group class="mb-0">
          <label class="font-weight-bold mt-50">
            {{ $t("backoffice.settings.exclude-requirements") }}
          </label>
          <div class="d-flex mt-25">
            <b-form-checkbox switch v-model="invited.bypassForm" />
            <p>{{ $t("bypass-form") }}</p>
            <b-form-checkbox
              switch
              v-model="invited.bypassPlans"
              class="ml-2"
            />
            {{ $t("bypass-plans") }}
          </div>
        </b-form-group>
      </div>

      <!-- Email invitation -->
      <label class="font-weight-bold mt-50">
        {{ $t("backoffice.settings.invitation-email") }}
      </label>
      <b-form-group class="mb-0">
        <div class="d-flex mt-25">
          <b-form-checkbox switch v-model="invited.notification" />
          <p>{{ $t("posts.post-creation.action.email-notification") }}</p>
        </div>
      </b-form-group>

      <b-form-group v-if="invited.notification">
        {{ $t("backoffice.settings.email-message") }}
        <quill-editor
          id="message"
          v-model="invited.message"
          class="mt-1"
          name=" "
          :placeholder="$t('products.contact.modal.message-placeholder')"
          rows="8"
        />
      </b-form-group>

      <b-form-group
        v-if="isHybridEvent"
      >
        <label for="modality" class="font-weight-bold">
          {{ $t("events.actions.modality-options") }}
        </label>
        <v-select
          v-if="availableCapacity"
          id="modality"
          v-model="invited.modality"
          :options="modalityOptions"
          :reduce="(modality) => modality.code"
          label="modality"
          :placeholder="$t('form.select-placeholder')"
          :clearable="false"
        />
        <p v-else class="text-danger font-weight-bold">
          {{ $t("events.details.completed-capacity") }}
        </p>
      </b-form-group>

      <!-- -->
      <b-form-group v-for="(email, i) in emails" :key="i" class="flex-grow-1">
        <hr />
        <b-row>
          <b-col>
            <b-form-group class="flex-grow-1">
              <label for="invited-name" class="font-weight-bold">
                {{ $t("backoffice.roles.name") }}
              </label>
              <b-form-input
                id="invited-name"
                v-model="emails[i].name"
                type="text"
                :placeholder="$t('form.type-placeholder')"
              />
            </b-form-group>
          </b-col>
          <b-col>
            <b-form-group class="flex-grow-1">
              <label for="invited-surname" class="font-weight-bold">
                {{ $t("backoffice.owner.surname") }}
              </label>
              <b-form-input
                id="invited-surname"
                v-model="emails[i].surname"
                type="text"
                :placeholder="$t('form.type-placeholder')"
              />
            </b-form-group>
          </b-col>
        </b-row>
        <label for="email-name" class="font-weight-bold">
          {{ $t("backoffice.settings.email-label") }}*
        </label>
        <b-form-input
          id="email-name"
          v-model="emails[i].email"
          type="email"
          :placeholder="$t('form.type-placeholder')"
          :state="isValidArray[i]"
          @input="validatorEmailsArray(emails[i].email, i)"
        />
        <!-- Bypass -->
        <label class="font-weight-bold mt-50">
          {{ $t("backoffice.settings.exclude-requirements") }}
        </label>
        <div class="d-flex flex-wrap">
          <div class="d-flex mt-25">
            <b-form-checkbox switch v-model="emails[i].bypassForm" />
            <p>{{ $t("bypass-form") }}</p>
            <b-form-checkbox
              switch
              v-model="emails[i].bypassPlans"
              class="ml-2"
            />
            {{ $t("bypass-plans") }}
          </div>
        </div>
        <!-- Email invitation -->
        <label for="email-name" class="font-weight-bold mt-50">
          {{ $t("backoffice.settings.invitation-email") }}
        </label>
        <div class="d-flex mt-25">
          <b-form-checkbox switch v-model="emails[i].sendMail" />
          <p>{{ $t("posts.post-creation.action.email-notification") }}</p>
        </div>
        <b-form-group v-if="emails[i].sendMail">
          {{ $t("backoffice.settings.email-message") }}
          <quill-editor
            id="message"
            class="mt-1"
            v-model="emails[i].customMssg"
            name=" "
            :placeholder="$t('products.contact.modal.message-placeholder')"
            rows="8"
          />
        </b-form-group>
        <b-form-group
          v-if="isHybridEvent"
        >
          <label for="type" class="font-weight-bold">
            {{ $t("events.actions.modality-options") }}
          </label>
          <v-select
            v-if="availableCapacity"
            id="type"
            v-model="emails[i].modality"
            :options="modalityOptions"
            :reduce="(modality) => modality.code"
            :placeholder="$t('form.select-placeholder')"
            label="modality"
            :clearable="false"
          />
          <p v-else class="text-danger font-weight-bold">
            {{ $t("events.details.completed-capacity") }}
          </p>
        </b-form-group>
      </b-form-group>
      <b-button
        variant="link"
        :disabled="!isValid"
        class="p-0 pointer font-weight-bold"
        @click="addEmail()"
      >
        {{ $t("backoffice.members.add-member") }}
      </b-button>
      <br />
      <div
        class="d-flex flex-wrap align-items-center justify-content-between mt-1"
      >
        <b-button
          variant="link"
          class="p-0 mt-50 pointer font-weight-bold"
          @click="uploadFileVisible = !uploadFileVisible"
        >
          {{ $t("backoffice.members.modals.import-members") }}
        </b-button>
        <b-badge
          variant="light-primary"
          class="ml-25 mt-50 font-weight-normal pointer"
          @click="downloadCsv()"
        >
          <feather-icon icon="DownloadIcon" class="mr-25" />
          {{ $t("download.csv") }}
        </b-badge>
      </div>
      <file-upload
        v-if="uploadFileVisible"
        v-model="csvFile"
        type="csv"
        class="w-100 mt-1"
        cancelable
        :placeholder="$t('media.form.file.placeholder')"
        :drop-placeholder="$t('media.form.file.drop-placeholder')"
      />
    </div>
    
    <b-row 
      v-else 
      class="horizontal-placeholder flex-column"
    >
      <b-col cols="12">
        <feather-icon
          icon="UserXIcon"
          size="40"
          class="m-0 d-block mx-auto text-danger mb-2"
        />
      </b-col>
      <b-col cols="12">
        <p class="text-danger">
          {{ $t("events.details.completed-capacity") }}
        </p>
      </b-col>
    </b-row>
  </b-modal>
</template>


<script>
import vSelect from "vue-select";
import { translateTranslationTable } from "@/@core/libs/i18n/utils";
// import { validatorEmailValidator } from "@/@core/utils/validations/validators";
import FileUpload from "@core/components/files/FileUpload.vue";
import ExportModal from "@core/components/modal/ExportModal.vue";
// import Ripple from "vue-ripple-directive";
import Placeholder from "@/assets/images/placeholders/light/general-placeholder.svg";
import { quillEditor } from "vue-quill-editor";
import ToastNotificationsMixin from '@core/mixins/toast-notifications/ToastNotificationsMixin';

export default {
  name: "InviteMembersModal",
  components: {
    vSelect,
    FileUpload,
    ExportModal,
    quillEditor,
  },
  mixins: [ToastNotificationsMixin],
  props: {
  },
  data() {
    return {
      invited: {
        email: "",
        member: true,
        staff: false,
        notification: true,
        modality: "",
        bypassForm: false,
        bypassPlans: false,
      },
      emails: [],
      classifiersPairs: "",
      isValidArray: [],
      isLoading: false,
      filtersIndex: 0,
      filters: [],
      parentClassifiers: [],
      timeoutID: null,
      areFiltersVisible: false,
      allClassifierFilters: [],
      nextFilter: [],
      selectedClassifiersForFilter: [],
      finalSelectClassifier: [],
      selectClassifierParent: [],
      selectedCustomFieldsForFilter: [],
      index: 0,
      actualData: [],
      customFieldsInputs: [],
      customFieldToSend: {},
      isLoadingClassifiers: true,
      isValid: null,
      classifiers: [],
      csvFile: null,
      uploadFileVisible: false,
      page: 1,
      search: false,
      isExportModalOpened: false,
      isUpload: false,
      isLoadingButton: false,
      isSmall: false,
      csvTemplateEmails: [
        ["Name", "Surname", "email"],
        ["Name1", "Surname1", "email1@example.com"],
        ["Name2", "Surname2", "email2@example.com"],
      ],
      canShowOnline: true,
      canShowOnsite: true,
    };
  },
  computed: {
    placeholder() {
      return Placeholder;
    },
    formIsVisible() {
      if (this.currentCommunity.isEvent) {
        console.log('this.currentCommunity.modality', this.currentCommunity.modality);
        console.log('this.canShowOnline', this.canShowOnline);
        console.log('this.canShowOnsite', this.canShowOnsite);
        if (this.currentCommunity.modality === "online") {
          return this.canShowOnline;
        }
        if (this.currentCommunity.modality === "on-site") {
          return this.canShowOnsite;
        }
        if (this.currentCommunity.modality === "hybrid") {
          return this.canShowOnline || this.canShowOnsite;
        }
      }
      return true;
    },
    modalityOptions() {
      let options = [
        { code: 0, modality: this.$t("events.event-details.online") },
        { code: 1, modality: this.$t("events.event-details.on-site") },
      ];
      if (!this.canShowOnline) {
        options.splice(0, 1);
        if (!this.canShowOnsite) {
          options.splice(0, 1);
        }
      } else if (!this.canShowOnsite) {
        options.splice(1, 1);
      }
      return options;
    },
    file() {
      return this.$store.getters.file;
    },
    currentLocale() {
      return this.$store.state.locale.currentLocale;
    },
    currentCommunity() {
      return this.$store.getters.currentCollective;
    },
    isHybridEvent() {
      // console.log('this.currentCommunity.isEvent', this.currentCommunity.isEvent);
      // console.log('this.currentCommunity.modality', this.currentCommunity.modality);
      return this.currentCommunity.isEvent && this.currentCommunity.modality === 'hybrid';
    },
    availableCapacity() {
      // console.log('this.canShowOnline', this.canShowOnline);
      // console.log('this.canShowOnsite', this.canShowOnsite);
      return this.canShowOnline || this.canShowOnsite;
    },
    hasOccupationAdded() {
      console.log('this.currentCommunity.occupation', this.currentCommunity.occupation);
      return this.currentCommunity.occupation != null &&
          (this.currentCommunity.occupation['on-site'] ||
            this.currentCommunity.occupation['online']);
    }
  },
  watch: {
    //
  },
  async created() {
    //
  },
  methods: {
    actualInputData(data) {
      this.actualData = data;
      const dat = [];
      for (const row of data) {
        dat.push({
          name: translateTranslationTable(
            this.$store.state.locale.currentLocale,
            row.name
          ),
          key: row.key,
        });
      }
      return dat;
    },
    emailValidator(item) {
      this.isValid = this.validateEmail(item);
    },
    validatorEmailsArray(item, i) {
      this.isValidArray[i] = this.validateEmail(item);
    },
    validateEmail(email) {
      // console.log('emailValidator input', email);
      return true;

      //TODO: the regex validator in the next function does not accept ".barcelona" as a valid domain because it's too long
      // return this.validatorEmailValidator(email) && email !== "";
    },
    async inviteMember() {
      this.isLoading = true;

      // If the invitation is from CSV file...
      if (this.csvFile !== null) {
        console.log('Inviting through CSV file import...')
        const msg = {};
        msg[this.currentLocale] = this.invited.message;
        this.isLoadingButton = true;
        this.isUpload = true;
        try {
          await this.$store.dispatch("importFiles", {
            importType: "backoffice/members/import",
            file: this.csvFile,
            forceUpdate: true,
            customName: "communityMembers",
            storedKey: this.$store.getters.currentCollective.slug,
            listingType: "",
            requestConfig: {
              isMember: true,
              isStaff: false,
              customMsg: msg,
              sendMail: this.invited.notification,
              ...(this.currentCommunity.isEvent
                ? { modality: this.invited.modality }
                : ""),
              bypassForm: this.invited.bypassForm,
              bypassPlans: this.invited.bypassPlans,
            },
          });
          this.isLoadingButton = false;
          this.isUpload = false;
          this.notifySuccess(
            this.$t("backoffice.members.messages.import-success")
          );
        } catch (e) {
          console.log(e);
          this.notifyError(this.$t("backoffice.members.messages.import-error"));
        }
        this.uploadFileVisible = false;
      }

      // If the invitation is not with CSV file...
      this.handleInvite();
      this.isLoading = false;
      this.isValid = null;
      this.reset();
    },
    async handleInvite() {
      const allValid = this.isValidArray.every(
        (item) => item === true || item === null
      );
      if (this.isValid && allValid) {
        this.emails.push({
          email: this.invited.email,
          name: this.invited.name,
          surname: this.invited.surname,
          isMember: true,
          isStaff: false,
          customMssg: this.invited.message,
          sendMail: this.invited.notification,
          ...(this.currentCommunity.isEvent
            ? { modality: this.invited.modality }
            : ""),
          bypassForm: this.invited.bypassForm,
          bypassPlans: this.invited.bypassPlans,
        });
        const emails = this.emails.map(({ customMssg, ...rest }) => {
          const msg = {};
          msg[this.currentLocale] = customMssg;
          const customMsg = msg;
          return { customMsg, ...rest };
        });
        this.isValid = null;
        this.isValidArray = [];
        try {
          const response = await this.$store.dispatch("inviteMembers", {
            emails: emails,
          });
          console.log('handleInvite response', response);
          if (response.status === 200) {
            this.notifySuccess(this.$t("backoffice.members.messages.success"));
            this.$emit('invited', 'member');
          } else if (response.status === 403) {
            this.$bvModal.show("error-modal");
          }
          this.emails = [];
          this.reset();
        } catch (error) {
          this.notifyError(this.$t("backoffice.members.messages.error"));
          this.reset();
        }
      }
    },
    addEmail() {
      if (this.isValid) {
        this.emails.push({
          email: this.invited.email,
          name: this.invited.name,
          surname: this.invited.surname,
          isMember: true,
          isStaff: false,
          customMssg: this.invited.message,
          sendMail: this.invited.notification,
          ...(this.currentCommunity.isEvent
            ? { modality: this.invited.modality }
            : ""),
          bypassForm: this.invited.bypassForm,
          bypassPlans: this.invited.bypassPlans,
        });
        this.invited.email = "";
        this.invited.name = "";
        this.invited.surname = "";
        this.invited.member = true;
        this.invited.staff = false;
        (this.invited.bypassPlans = false),
          (this.invited.bypassForm = false),
          (this.invited.notification = true);
        this.invited.message = "";
        this.isValid = null;
        if (
          this.currentCommunity.isEvent &&
          this.currentCommunity.modality === "hybrid"
        ) {
          this.invited.modality = this.modalityOptions[0].code;
        }
      }
    },
    reset() {
      this.invited.email = "";
      this.invited.name = "";
      this.invited.surname = "";
      this.invited.member = true;
      this.invited.staff = false;
      (this.invited.bypassPlans = false),
        (this.invited.bypassForm = false),
        (this.invited.notification = true);
      this.invited.message = "";
      this.emails = [];
      this.isValidArray = [];
      this.csvFile = null;
      this.uploadFileVisible = false;
      if (
        this.currentCommunity.isEvent &&
        this.currentCommunity.modality === "hybrid"
      ) {
        this.invited.modality = this.modalityOptions[0].code;
      }
    },
    translateTranslationTable,
    getCapacity(occupationType) {
      if (this.currentCommunity.modality === "online") {
        return `${
          this.currentCommunity.onlineOccupation
            ? this.currentCommunity.onlineOccupation
            : 0
        } / ${
          this.currentCommunity.occupation.online
            ? this.currentCommunity.occupation.online
            : this.$t("events.details.without-capacity")
        }`;
      }
      if (this.currentCommunity.modality === "on-site") {
        return `${
          this.currentCommunity.onsiteOccupation
            ? this.currentCommunity.onsiteOccupation
            : 0
        } / ${
          this.currentCommunity.occupation["on-site"]
            ? this.currentCommunity.occupation["on-site"]
            : this.$t("events.details.without-capacity")
        }`;
      }
      if (this.currentCommunity.modality === "hybrid") {
        if (occupationType === "online") {
          return `${
            this.currentCommunity.onlineOccupation
              ? this.currentCommunity.onlineOccupation
              : 0
          } / ${
            this.currentCommunity.occupation.online
              ? this.currentCommunity.occupation.online
              : this.$t("events.details.without-capacity")
          }`;
        }
        if (occupationType === "on-site") {
          return `${
            this.currentCommunity.onsiteOccupation
              ? this.currentCommunity.onsiteOccupation
              : 0
          } / ${
            this.currentCommunity.occupation["on-site"]
              ? this.currentCommunity.occupation["on-site"]
              : this.$t("events.details.without-capacity")
          }`;
        }
      }
      return `${
        this.currentCommunity.occupation["on-site"] +
        this.currentCommunity.occupation.online -
        this.currentCommunity.currentOccupation
      } / ${
        this.currentCommunity.occupation["on-site"] +
        this.currentCommunity.occupation.online
      }`;
    },
  },
};
</script>