<template>
  <div class="w-100 p-0 overflow-hidden">
    <b-table
      class="position-relative"
      :items="members"
      responsive
      :small="isSmall"
      :fields="fields"
      :busy="busy"
      per-page="15"
      primary-key="id"
      show-empty
      :table-class="members.length < 5 ? 'mb-5' : ''"
    >
      <!-- Column: Member -->
      <template #cell(name)="{ item }">
        <b-media class="d-flex align-items-center pl-1 ml-25 mt-25">
          <template>
            <b-link :to="getMemberLocation(item)" class="text-dark d-flex align-items-center">
              <div>
                <user-avatar :user="item" size="32" variant="light-primary" />
              </div>
              <div class="ml-1">
                <p v-if="item.name" class="font-weight-extrabold mb-0">
                  {{ `${item.name} ${item.surname}` }}
                </p>
                <span v-else class="text-muted">---</span>
              </div>
            </b-link>
          </template>
        </b-media>
      </template>

      <!-- Column: Headline -->
      <!--template #cell(headline)="{ item }">
        <p v-if="item.headline" class="mb-0 pl-25 ml-1 text-capitalize truncated-text mt-75">
          {{ item.headline }}
        </p>
        <p v-else class="text-muted mb-0 pl-25 ml-1 mt-75">
          ---
        </p>
      </template-->

      <!-- Column: Emails -->
      <template #cell(email)="{ item }">
        <p class="align-text-top mb-0 pl-25 ml-1 mt-75">
          {{ item.email }}
        </p>
      </template>

      <!-- Column: Status -->
      <template #cell(status)="data">
        <div class="d-flex align-items-center">
          <b-badge
            v-if="data.item.isMember"
            v-b-tooltip.hover
            pill
            :title="data.item.status === 3 ? $t('tooltips.members.disable') : $t('tooltips.members.verify')"
            :variant="getColor(data.item.status)"
            class="text-capitalize mb-0 ml-1 mt-75 check-button"
            @click="handleStatusModals(data.item)"
          >
            {{ getStatus(data.item.status) }}
          </b-badge>
          <p v-else class="text-muted mb-0 ml-2 pl-75 mt-75">
            ---
          </p>
          <!-- Attendance icon -->
          <feather-icon
            v-if="data.item.status == 3 && data.item.attendOnsite"
            v-b-tooltip.hover
            :title="$t('backoffice.members.attendance-icon') "
            icon="UserCheckIcon"
            size="16"
            class="mt-25 ml-50 text-success pointer"
            @click="handleUnattendance(data.item)"
          />
          <!-- Attendance certificate -->
          <feather-icon
            v-if="data.item.status == 3 && !isSmall && isEventCourseOrChallenge"
            v-b-tooltip.hover
            :title="$t('backoffice.members.certificate-icon') "
            icon="AwardIcon"
            size="16"
            class="mt-25 ml-50 text-warning pointer"
            @click="handleCertificate(data.item)"
          />
        </div>
      </template>

      <!-- Column: Roles -->
      <template #cell(roles)="{ item }">
        <div v-if="item.roles && item.roles.length > 0" class="mt-75">
          <p 
            v-for="(role, i) in item.roles" 
            :key="i" 
            class="align-text-top mb-0 pl-25 ml-1 mt-25"
            :style="role.isGlobal ? 'color: purple;' : ''"
            :title="role.isGlobal ? 'Global role' : 'Local role'"
          >
            {{ getRoleName(role) }}
          </p>
        </div>
      </template>

      <!-- Column: Organizations -->
      <template #cell(organizations)="{ item }">
        <div
          v-for="(organization, index) in orgsFromMemberInMain(item)"
          :key="index"
          class="align-text-top mb-0 pl-25 ml-1 mt-25"
        >
          <p v-if="index < 4" class="mb-50">
            <b-link @click="organizationLocation(organization)">
              {{ typeof organization.name === 'object' ? translateTranslationTable(locale, organization.name ) : organization.name }}
            </b-link>
          </p>
          <small 
            v-if="index === 5" 
            class="text-secondary font-weight-bold cursor-pointer" 
            @click="showAllOrganizations(item)"
          >
            {{ $t("action.show-more") }} 
          </small>
        </div>
      </template>
      
      <!-- Column: progress -->
      <template #cell(progress)="{ item }">
        <b-progress 
          :max="totalCommunityCompletableElements" 
          :value="getProgress(item)"
          :variant="getProgressColor(item)"
          show-progress 
          animated
        />
      </template>
      
      <!-- Column: modality -->
      <template #cell(modality)="{ item }">
        <b-badge
          v-if="currentCommunity.isEvent && currentCommunity.modality === 'hybrid' && getModalityName(item) != null"
          pill
          :variant="item.modality === 'online' ? 'primary' : 'warning'"
          class="mb-0 pl-50 ml-1 mt-75"
        >
          <small class="font-weight-bold">{{ getModalityName(item) }}</small>
        </b-badge>
        <p v-else class="text-muted mb-0 pl-25 ml-1 mt-75">
          ---
        </p>
      </template>

      <!-- Column: Updated date time -->
      <template #cell(updatedTime)="{ item }">
        <p class="align-text-top text-capitalize mb-0 pl-25 ml-1 mt-75">
          {{ getDate(item) }}
        </p>
      </template>

      <!-- Column: Actions -->
      <template #cell(actions)="data">
        <b-dropdown
          id="dropdown-dropleft"
          variant="link"
          no-caret
          :dropup="members.length < 2"
          :dropleft="members.length <= 2"
          class="ml-2"
          @shown="data.item.status === 3 ? getAttendance(data.item) : ''"
        >
          <template #button-content>
            <feather-icon icon="MoreVerticalIcon" size="16" />
          </template>
          <b-dropdown-header id="dropdown-header-label" class="bg-light">
            <p v-if="data.item.email" class="font-weight-extrabold mb-0">
              {{ `${data.item.name} ${data.item.surname}` }} <span v-if="data.item.name">(</span>{{ data.item.email }}<span v-if="data.item.name">)</span>
            </p>
          </b-dropdown-header>
          <b-dropdown-item @click="selectMember(data.item)">
            <feather-icon icon="UsersIcon" class="mr-50" />
            {{
              $t('members.member-detail.connections')
            }}
          </b-dropdown-item>
          <b-dropdown-item @click="handleOpenRemoveAllModal(data.item)">
            <feather-icon icon="XCircleIcon" class="mr-50" />
            {{ $t('backoffice.members.disable-all') }}
          </b-dropdown-item>
          <b-dropdown-item v-if="isOwner" @click="handleOpenRemoveDefinitelyModal(data.item)">
            <feather-icon icon="Trash2Icon" class="mr-50" />
            {{ $t('organizations.backoffice.settings.members.remove-member') }}
          </b-dropdown-item>
          <b-dropdown-item
            v-if="$store.getters.currentCollective.hasForm"
            @click="handleOpenOnboardingModal(data.item)"
          >
            <feather-icon icon="ClipboardIcon" class="mr-50" />
            {{ $t('backoffice.members.onboarding') }}
          </b-dropdown-item>
          <b-dropdown-item @click="roleEdit(data.item)">
            <feather-icon icon="UnlockIcon" class="mr-50" />
            {{ $t('members.roles.edit') }}
          </b-dropdown-item>
          <!-- <b-dropdown-item @click="tokenEdit(data.item)">
            <feather-icon icon="LinkIcon" class="mr-50" />
            {{ $t('share.token-verification')}}
          </b-dropdown-item> -->
          <b-dropdown-item v-if="!data.item.attendOnsite && data.item.status === 3 && attendance && attendance.length === 0" @click="validateAttendance(data.item)">
            <feather-icon icon="UserCheckIcon" class="mr-50" />
            {{ $t('backoffice.members.attendance') }}
          </b-dropdown-item>
          <b-dropdown-item v-if="!mainSpaceSlug || (mainSpaceSlug && data.item.status === 3) || hasCommunityBypass" @click="registerEmails(data.item)">
            <feather-icon icon="SendIcon" class="mr-50" />
            {{ $t('backoffice.members.auth-emails') }}
          </b-dropdown-item>
          <div v-if="!isAttendanceLoading">
            <b-dropdown-divider v-if="data.item.attendOnsite && data.item.status === 3 && attendance && attendance.length > 0" />
            <b-dropdown-text v-if="data.item.status === 3 && attendance && attendance.length > 0" variant="success">
              <p class="mb-0">
                <feather-icon icon="UserCheckIcon" class="mr-50 font-weight-bold" />{{ $t('backoffice.members.attendance-message') }} {{ getValidationDate(data.item) }}
              </p>
            </b-dropdown-text>
          </div>
        </b-dropdown>
      </template>
      <template #table-busy>
        <div class="text-center p-5">
          <b-spinner
            size="2rem"
            type="grow"
            class="d-block m-auto m-1"
            variant="primary"
            label="Loading..."
          />
        </div>
      </template>
      <template #empty>
        <div class="horizontal-placeholder d-block">
          <div v-if="search && members && members.length === 0">
            <div>
              <img :src="membersPlaceholder" class="d-block">
            </div>
            <div>
              <p class="text-primary text-center">
                {{ $t('available.message', { itemName: $t('members.title') }) }}
              </p>
            </div>
          </div>
          <b-spinner
            v-else
            size="2rem"
            small
            type="grow"
            class="d-block m-auto m-1"
            variant="primary"
            label="Loading..."
          />
        </div>
      </template>
    </b-table>
    <teleport to="body">
      <request-meeting-modal v-model="isRequestMeetingVisible" :member="memberForMeeting" />
    </teleport>
    
    <!-- Info: Can't validate -->
    <b-modal
      id="staff-modal"
      v-model="isInfoModalOpened"
      :title="$t('backoffice.members.modals.info-validate')"
      :ok-title="$t('form.actions.save')"
      size="sm"
      centered
      hide-footer
    >
      <feather-icon size="30" icon="AlertTriangleIcon" class="d-block mx-auto text-danger mb-1" />
      <h5 class="mb-25 px-2 text-center">
        {{ $t('backoffice.members.modals.info-validate') }}.
      </h5>
      <h5 class="mb-2 px-2 text-center">
        {{ $t('backoffice.members.modals.info-message') }}.
      </h5>
      <div class="text-center mb-1">
        <b-button
          variant="primary"
          class="mt-25"
          @click="isInfoModalOpened = false"
        >
          {{ $t('form.actions.ok') }}
        </b-button>
      </div>
    </b-modal>

    <!-- Can't disable staff if member is Owner -->
    <b-modal
      id="staff-modal-owner"
      v-model="isInfoModalOwnerOpened"
      :title="$t('backoffice.members.modals.info-owner')"
      :ok-title="$t('form.actions.save')"
      size="sm"
      centered
      hide-footer
    >
      <feather-icon size="30" icon="AlertTriangleIcon" class="d-block mx-auto text-danger mb-1" />
      <h5 class="mb-2 px-2 text-center">
        {{ $t('backoffice.members.modals.info-owner-message') }}.
      </h5>
      <div class="text-center mb-1">
        <b-button
          variant="primary"
          class="mt-25"
          @click="isInfoModalOwnerOpened = false"
        >
          {{ $t('form.actions.ok') }}
        </b-button>
      </div>
    </b-modal>

    <!-- Member Modal -->
    <b-modal
      id="member-modal"
      v-model="isMemberModalOpened"
      :title="currentMember.isMember ? $t('backoffice.members.disable') : $t('backoffice.members.accept')"
      :ok-title="$t('form.actions.save')"
      size="sm"
      centered
      no-close-on-backdrop
      hide-footer
    >
      <b-form-group>
        <p class="font-weight-bold mt-1">
          {{
            currentMember.isMember
              ? $t('backoffice.members.modals.member-disable-message')
              : $t('backoffice.members.modals.member-accept-message')
          }}
        </p>
        <p class="font-weight-bolder mt-1 text-center">
          {{ currentMember.name }} {{ currentMember.surname }} ({{ currentMember.email }})
        </p>
        <div v-if="!currentMember.isMember">
          <label class="mt-25 font-weight-bold">{{ $t('backoffice.members.enable-status') }}:</label>
          <v-select
            v-model="enableStatus"
            class="select-control p-0 mb-1"
            :clearable="false"
            :searchable="false"
            :options="Object.values(optionsEnableMember)"
          />
        </div>
        <div class="text-center mb-2">
          <b-button
            class="font-weight-bold"
            :variant="currentMember.isMember ? 'danger' : 'primary'"
            @click="toggleIsMember()"
          >
            {{ currentMember.isMember ? $t('backoffice.members.disable') : $t('backoffice.members.accept') }}
          </b-button>
        </div>
      </b-form-group>
    </b-modal>

    <!-- Status Modal -->
    <b-modal
      id="status-modal"
      v-model="isStatusModalOpened"
      :title="isVerified ? $t('backoffice.members.disable-status') : $t('backoffice.members.accept-status')"
      :ok-title="$t('form.actions.save')"
      :size="currentMember.status == 7 ? 'lg' : 'sm'"
      hide-footer
      @ok="toggleIsVerified()"
    >
      <b-form-group>
        <b-alert :show="currentMember.status == 7" variant="danger">
          <div class="ml-1 mb-50">
            <feather-icon size="18" icon="AlertTriangleIcon" class="d-block mx-auto text-danger mb-50" />
            {{ $t('member-message') }}
          </div>
        </b-alert>

        <p class="font-weight-bold mt-1 text-center">
          {{
            isVerified
              ? $t('backoffice.members.modals.status-disable-message')
              : $t('backoffice.members.modals.status-accept-message')
          }}
        </p>
        <p class="font-weight-bolder mt-1 text-center">
          {{ currentMember.name }} {{ currentMember.surname }} ({{ currentMember.email }})
        </p>
        <div class="text-center mb-2">
          <b-button class="font-weight-bold" :variant="isVerified ? 'danger' : 'primary'" @click="toggleIsVerified()">
            {{ isVerified ? $t('backoffice.members.disable-status') : $t('backoffice.members.accept-status') }}
          </b-button>
        </div>
      </b-form-group>
    </b-modal>

    <!-- Remove all -->
    <b-modal
      id="revove-all-modal"
      v-model="isRemoveAllModalOpened"
      :title="$t('backoffice.members.remove-all')"
      :ok-title="$t('form.actions.save')"
      size="sm"
      hide-footer
      @ok="handleDisableAll()"
    >
      <b-form-group>
        <p class="font-weight-bold mt-1  text-center">
          {{ $t('backoffice.members.remove-message') }}
        </p>
        <p class="font-weight-bolder mt-1 text-center">
          {{ currentMember.name }} {{ currentMember.surname }} ({{ currentMember.email }})
        </p>
        <div class="text-center mb-2">
          <b-button class="font-weight-bold" variant="danger" @click="handleDisableAll()">
            {{ $t('backoffice.members.disable-all') }}
          </b-button>
        </div>
      </b-form-group>
    </b-modal>

    <!-- Remove definitely -->
    <b-modal
      id="revove-all-modal"
      v-model="isRemoveDefinitelyModalOpened"
      :title="$t('organizations.backoffice.settings.members.remove-member')"
      :ok-title="$t('form.actions.save')"
      :size="isMedium ? 'xl' : 'lg'"
      hide-footer
    >
      <b-form-group>
        <p class="font-weight-bold mt-1 mb-0 text-center">
          {{ $t('organizations.backoffice.settings.members.remove-message-bo') }}
        </p>
        <p class="font-weight-bold text-center">
          {{ $t('organizations.backoffice.settings.members.explanation-message-bo') }}
        </p>
        <p class="font-weight-bolder mt-1 text-center">
          {{ currentMember.name }} {{ currentMember.surname }} ({{ currentMember.email }})
        </p>
        <div class="text-center mb-2">
          <b-button class="font-weight-bold" variant="danger" @click="handleDisableDefinitely()">
            {{ $t('organizations.backoffice.settings.members.remove-member') }}
          </b-button>
        </div>
      </b-form-group>
    </b-modal>

    <!-- Remove Attendance -->
    <b-modal
      id="revove-attendance-modal"
      v-model="isRemoveAttendanceModalOpened"
      :title="$t('organizations.backoffice.settings.attendance.remove')"
      size="lg"
      hide-footer
    >
      <b-form-group>
        <p class="font-weight-bold text-center">
          {{ $t('organizations.backoffice.settings.attendance.remove-message') }}
        </p>
        <p class="font-weight-bolder mt-1 text-center">
          {{ currentMember.name }} {{ currentMember.surname }} ({{ currentMember.email }})
        </p>
        <div class="text-center mt-2 mb-1">
          <b-button variant="primary" class="font-weight-bold mr-md-2 mt-50" @click="isRemoveAttendanceModalOpened = false">
            {{ $t('organizations.backoffice.settings.attendance.options.no') }}
          </b-button>
          <b-button variant="outline-danger" class="font-weight-bold  mt-50" @click="handleRemoveAttendance()">
            {{ $t('organizations.backoffice.settings.attendance.options.yes') }}
          </b-button>
        </div>
      </b-form-group>
    </b-modal>
    
    <!-- Answer Requested -->
    <b-modal
      id="requested-modal"
      v-model="isRequestedModalOpened"
      :title="$t('backoffice.members.answer-request')"
      :ok-title="$t('form.actions.save')"
      size="sm"
      hide-footer
    >
      <b-form-group>
        <div>
          <label class="mt-25 font-weight-bold">{{ $t('backoffice.members.select-request') }}:</label>
          <v-select
            v-model="answerRequest"
            class="select-control p-0 mb-1"
            :clearable="false"
            :searchable="false"
            label="name"
            :options="requestedOptions"
          />
        </div>
        <p class="font-weight-bolder mt-1 text-center">
          {{ currentMember.name }} {{ currentMember.surname }} ({{ currentMember.email }})
        </p>
        <div class="text-center mb-2">
          <b-button class="font-weight-bold" variant="primary" @click="sendAnswerRequested()">
            {{ $t('form.actions.save') }}
          </b-button>
        </div>
      </b-form-group>
    </b-modal>

    <!-- Onboarding modal -->
    <b-modal
      id="revove-all-modal"
      v-model="isOnboardingModalOpened"
      :title="$t('backoffice.members.onboarding')"
      :ok-title="$t('form.actions.save')"
      size="lg"
      hide-footer
      @hidden="currentMember = {}"
    >
      <div class="text-dark d-flex align-items-center">
        <div>
          <user-avatar :user="currentMember" size="32" />
        </div>
        <div class="ml-1">
          <p v-if="currentMember.name" class="font-weight-extrabold mb-0">
            {{ `${currentMember.name} ${currentMember.surname}` }} ({{ currentMember.email }})
          </p>
          <span v-else class="font-weight-extrabold">{{ currentMember.email }}</span>
          <p v-if="currentMember.headline" class="mb-0 text-capitalize truncated-text mt-75">
            {{ currentMember.headline }}
          </p>
        </div>
      </div>
      <hr>
      <div v-if="onboardingAnswers && Object.values(onboardingAnswers).length > 0 && !isLoading">
        <div v-for="(value, index) in onboardingAnswers" :key="index" class="mb-1">
          <p class="font-weight-bold mb-25">
            {{ translateTranslationTable(locale, value.questionName) }}
          </p>
          <p v-if="typeof onboardingAnswers[index]==='object' && Object.keys(onboardingAnswers[index]).includes('response')">
            {{ onboardingAnswers[index].response || '--' }}
          </p>
          <p v-else>
            {{ onboardingAnswers[index] || '--' }}
          </p>
        </div>
        <b-spinner
          v-if="isLoading"
          type="grow"
          small
          class=" text-primary d-block m-auto"
        />
      </div>
      <div v-else class="text-primary text-center font-weight-bold mb-1">
        {{ $t('backoffice.dashboard.no-data') }}
      </div>
    </b-modal>

    <!-- Connections -->
    <b-modal
      v-if="selectedMember"
      id="modal-create-jobs"
      v-model="isConnectionModalVisible"
      :title="$t('members.relation-modal')"
      centered
      size="lg"
      hide-footer
    >
      <member-add-connections-modal
        :is-table="true"
        :selected-member="selectedMember"
        @addConnection="addConnection"
        @restConnection="restConnection"
      />
    </b-modal>

    <!-- Roles Management Modal -->
    <b-modal
      v-model="isRolesModalVisible"
      :title="$t('members.roles.edit')"
      :ok-title="$t('form.actions.save')"
      no-close-on-backdrop
      @ok="setRoles"
    >
      <b-form-group>
        <label for="profile-role"> {{ $t('members.roles.title') }}*</label>
        <v-select
          id="type"
          v-model="rolesSelected"
          multiple
          :placeholder="$t('form.select-placeholder')"
          :options="communityRolesOptions"
          label="name"
        />
        <small>* {{ $t('backoffice.roles.alert') }}</small>
      </b-form-group>
    </b-modal>

    <!-- Register emails -->
    <b-modal
      v-model="isRegisterModalVisible"
      :title="$t('backoffice.members.auth-emails')"
      hide-footer
      @hidden="isCopied = false"
    >
      <div class="d-flex flex-column justify-content-center">
        <p class="font-weight-bolder mt-1 text-center mb-50">
          {{ currentMember.name }} {{ currentMember.surname }} ({{ currentMember.email }})
        </p>
        <b-badge
          v-if="currentMember.isMember"
          pill
          :variant="getColor(currentMember.status)"
          class="text-capitalize check-button align-self-center mb-1"
          @click="handleStatusModals(currentMember)"
        >
          {{ getStatus(currentMember.status) }}
        </b-badge>
      </div>
      <!-- Verification emails -->
      <b-card v-if="!mainSpaceSlug || hasCommunityBypass">
        <div class="d-flex flex-wrap align-items-center justify-content-between">
          <div>
            <h5>{{ $t('share.verification-email') }}</h5>
            <div v-if="token !== 'Internal Server Error' && token.mailsCount">
              <small v-if="token.lastMailDate" class="mb-0 uppercase text-muted">{{ $t('share.last-mail-date') }}</small><p class="mb-0">
                <small>{{ new Date(token.lastMailDate).toLocaleString() }}</small>
              </p>
              <hr>
              <small v-if="token.mailsCount" class="mb-0 uppercase text-muted mr-50">{{ $t('share.num-emails-sent') }}</small>
              <small>{{ token.mailsCount }}</small>
            </div>
          </div>
          <b-button variant="primary" class="mt-25" @click="sendRegistrationEmail('RegisterToken')">
            {{ token.mailsCount > 0 ? $t('form.actions.resend') : $t('form.actions.send') }}
          </b-button>
        </div>
      </b-card>
      <!-- Verification email link (with token) -->
      <b-card v-if="!mainSpaceSlug || hasCommunityBypass">
        <h5>{{ $t('share.token-verification') }}</h5>
        <b-form-group class="my-2">
          <b-input-group>
            <b-form-input v-model="token.registerToken" readonly />
            <b-input-group-append>
              <b-button variant="primary" @click="handleRegisterTokenClipboardCopied">
                <feather-icon icon="CopyIcon" />
              </b-button>
            </b-input-group-append>
          </b-input-group>
          <b-alert :show="isCopied" variant="primary" class="text-center mt-1 d-block mx-auto">
            {{ $t('share.copied-message') }}
          </b-alert>
        </b-form-group>
      </b-card>
      <!-- Reset password (with token) -->
      <b-card v-if="(!mainSpaceSlug || hasCommunityBypass) && token.passwordToken">
        <h5>{{ $t('share.token-reset-password') }}</h5>
        <b-form-group class="my-2">
          <b-input-group>
            <b-form-input v-model="token.passwordToken" readonly />
            <b-input-group-append>
              <b-button variant="primary" @click="handleResetPasswordTokenClipboardCopied">
                <feather-icon icon="CopyIcon" />
              </b-button>
            </b-input-group-append>
          </b-input-group>
          <b-alert :show="isCopied" variant="primary" class="text-center mt-1 d-block mx-auto">
            {{ $t('share.copied-message') }}
          </b-alert>
        </b-form-group>
      </b-card>
      <!-- Welcome email -->
      <b-card v-if="currentMember.status == 3">
        <div class="d-flex flex-wrap align-items-center justify-content-between">
          <h5>{{ $t('share.welcome-email') }}</h5>
          <b-button variant="primary" @click="sendRegistrationEmail('Welcome')">
            {{ $t('form.actions.send') }}
          </b-button>
        </div>
      </b-card>
    </b-modal>

    <!-- Member organizations -->
    <b-modal
      v-model="isOrganizationsModalVisible"
      :title="$t('members.member-organizations')"
      :ok-title="$t('form.actions.save')"
      hide-footer
    >
      <p class="font-weight-bolder mt-1 text-center">
        {{ currentMember.name }} {{ currentMember.surname }} ({{ currentMember.email }})
      </p>
      <div
        v-for="(organization, index) in orgsFromCurrentMember"
        :key="index"
        class="align-text-top text-capitalize mb-0 pl-25 ml-1 mt-25"
      >
        <p class="mb-50">
          <b-link @click="organizationLocation(organization)">
            {{ organization.name }}
          </b-link>
        </p>
        <hr>
      </div>
    </b-modal>

    <!-- Certificate -->
    <b-modal
      v-model="isCertificateAttendanceModalOpened"
      :title="$t('backoffice.members.certificate.title')"
      size="lg"
      hide-footer
    >
      <certificate :logged-member="currentMember" />
    </b-modal>

    <!-- Error modal -->
    <error-modal
      v-ripple.400="'rgba(234, 84, 85, 0.15)'"
      :title="$t('backoffice.members.messages.error-admin')"
      :subtitle="$t('appsumo.errors-modal.subtitle')"
    />
  </div>
</template>

<script>
import Teleport from 'vue2-teleport';
import { translateTranslationTable } from '@/@core/libs/i18n/utils';
// import { ENABLED_APPS_GETTERS } from '@/store/enabled-apps/enabled-apps-store-constants';
import { getImageResource } from '@/@core/utils/image-utils';
import UserAvatar from '@core/components/user/UserAvatar.vue';
import { CHAT_ACTIONS } from '@/views/apps/chat/constants/chat-store-constants';
import Service from '@/config/service-identifiers';
import MembersPlaceholder from '@/assets/images/placeholders/light/members.svg';
import RequestMeetingModal from '@/views/apps/member/modals/RequestMeetingModal.vue';
import ToastNotificationsMixin from '@core/mixins/toast-notifications/ToastNotificationsMixin';
import vSelect from 'vue-select';
import Ripple from "vue-ripple-directive";
import MedellinCertificate from '@core/components/members/CertificateMedellin.vue';
import Certificate from '@core/components/members/Certificate.vue';
import ErrorModal from '@core/components/modal/ErrorModal.vue';
import MemberAddConnectionsModal from '../../../../views/apps/member/modals/MemberAddConnectionsModal.vue';

export default {
  name: 'MembersTable',
  components: {
    Teleport,
    MemberAddConnectionsModal,
    UserAvatar,
    RequestMeetingModal,
    vSelect,
    MedellinCertificate,
    Certificate,
    ErrorModal,
  },
  mixins: [ToastNotificationsMixin],
  props: {
    members: {
      type: [Array, Function],
      default: () => [],
    },
    busy: Boolean,
    search: Boolean,
  },
  directives: {
    Ripple,
  },
  data() {
    return {
      isSmall: false,
      isMedium: false,
      memberForMeeting: null,
      isRequestMeetingVisible: false,
      isMember: false,
      isMemberModalOpened: false,
      isStatusModalOpened: false,
      isVerified: false,
      isRemoveAllModalOpened: false,
      currentMember: {},
      enableStatus: '',
      isRemoveDefinitelyModalOpened: false,
      isRemoveAttendanceModalOpened: false,
      isRequestedModalOpened: false,
      answerRequest: '',
      selectedMember: null,
      isConnectionModalVisible: false,
      isOnboardingModalOpened: false,
      isLoading: false,
      isRolesModalVisible: false,
      rolesSelected: [],
      rolesBackofficeSelected: [],
      communityRolesOptions: [],
      backofficeRolesOptions: [],
      mainSpaceMembers: [],
      organizations: [],
      orgsFromCurrentMember: [],
      isOrganizationsModalVisible: false,
      isTokenModalVisible: false,
      token: '',
      isCopied: false,
      isAttendanceLoading: true,
      isRegisterModalVisible: false,
      isInfoModalOpened: false,
      isCertificateAttendanceModalOpened: false,
      fields: [],
      isInfoModalOwnerOpened: false,
      currentCompletesPage: 1,
      currentCompletableElementsPage: 1,
        bars: [
          { variant: 'success', value: 75 },
          { variant: 'warning', value: 50 },
          { variant: 'danger', value: 25 },
        ],
    };
  },
  computed: {
    locale() {
      return this.$store.state.locale.currentLocale;
    },
    currentCollective() {
      return this.$store.getters.currentCollective.key;
    },
    currentCollectiveType() {
      return this.$store.getters.currentCollective.mainType;
    },
    mainCollective() {
      return this.$store.getters.mainCollective.key;
    },
    attendance() {
      return this.$store.getters.attendance.paginated[1];
    },
    membersError() {
      return this.$store.getters.membersError;
    },
    loggedMember() {
      return this.$store.getters.loggedMember;
    },
    mainSpaceSlug() {
      return this.$store.getters.mainCollective?.slug;
    },
    communityRoles() {
      return this.$store.getters.roles?.unpaginated;
    },
    memberRoles() {
      return this.$store.getters.memberRoles?.unpaginated;
    },
    requestedOptions() {
      return [
        { name: this.$t('backoffice.members.modals.accept-request'), value: true },
        { name: this.$t('backoffice.members.modals.reject-request'), value: false },
      ];
    },
    membersPlaceholder() {
      return MembersPlaceholder;
    },
    isEventCourseOrChallenge() {
      return this.currentCollectiveType === 'Event' || this.currentCollectiveType === 'Course' || this.currentCollectiveType === 'Challenge';
    },
    isOwner() {
      return this.$store.getters.loggedMemberRoles?.find(({ tagName }) => tagName === 'owner');
    },
    statusMember() {
      return {
        0: this.$t('backoffice.members.pending'),
        1: this.$t('backoffice.members.requested-join'),
        2: this.$t('backoffice.members.rejected'),
        3: this.$t('backoffice.members.confirmed'),
        4: this.$t('backoffice.members.rejected-admin'),
        5: this.$t('backoffice.members.rejected-user'),
        6: this.$t('backoffice.members.left'),
        7: this.$t('backoffice.members.invited'),
        8: this.$t('backoffice.members.waiting-form'),
        81: this.$t('backoffice.members.waiting-form'),
      };
    },
    optionsEnableMember() {
      return {
        0: this.$t('backoffice.members.pending'),
        1: this.$t('backoffice.members.requested-join'),
        3: this.$t('backoffice.members.confirmed'),
        7: this.$t('backoffice.members.invited'),
      };
    },
    onboardingAnswers() {
      if (this.$store.getters.onboardingAnswers?.unpaginated?.length > 0) {
        return this.$store.getters.onboardingAnswers?.unpaginated[0].answers;
      }
      return [];
    },
    onboardingAnswers2() {
      const formAnswers = this.onboardingAnswers;
      // console.log('formAnswers', formAnswers);
      if(formAnswers.length > 0){
        return formAnswers.answers;
      }
      return [];
    },
    currentCommunity() {
      return this.$store.getters.currentCollective;
    },
    hasCommunityBypass() {
      return this.mainSpaceSlug && this.currentCommunity.bypass;
    },
    collective() {
      return this.$store.getters.currentCollective;
    },
    completableElementsLastPage() {
      return this.$store.getters.completableElements.meta.last_page;
    },
    communityCompletableElements() {
      return this.$store.getters.completableElements.paginated[this.currentCompletableElementsPage] || [];
    },
    totalCommunityCompletableElements() {
      return this.$store.getters.completableElements?.meta?.total;
    },
    completesLastPage() {
      return this.$store.getters.completedItems.meta.last_page;
    },
    communityCompletes() {
      return this.$store.getters.completedItems.paginated[this.currentCompletesPage] || [];
    },
    totalCommunityCompletes() {
      return this.$store.getters.completedItems?.meta?.total;
    },
  },
  async created() {
    // console.log('this.members', this.members);

    // Columns...
    this.fields = [
      {
        key: 'name',
        label: this.$t('backoffice.members.fullname'),
        sortable: true,
      },
      // {
      //   key: 'headline',
      //   label: this.$t('members.edit.headline.label'),
      //   sortable: false,
      // },
      {
        key: 'email',
        label: this.$t('backoffice.settings.email-label'),
        sortable: true,
      },
      {
        key: 'roles',
        label: this.$t('members.roles.edit'),
        sortable: true,
      }
    ];

    // if all the members have status
    if(this.members.every(({ status }) => status != null)){
      this.fields.push({
        key: 'status',
        label: this.$t('backoffice.members.status'),
        sortable: true,
      });
    }

    // if the community is an event
    if(this.isEventCourseOrChallenge){
      this.fields.push({
        key: 'modality',
        label: this.$t('events.actions.modality-options'),
        sortable: true,
      });
    }
    
    // organizations if the community is main
    if(!this.mainSpaceSlug){
      this.fields.push({
        key: 'organizations',
        label: this.$t('members.edit.organizations.label'),
        sortable: true,
      });
    }
    
    // progress if the community has completable elements
    if(this.totalCommunityCompletableElements){
      this.fields.push({
        key: 'progress',
        label: this.$t('backoffice.members.progress'),
        sortable: true,
      });
    }
    this.fields.push({
      key: 'updatedTime',
      label: this.$t('backoffice.members.update'),
      sortable: true,
    });
    this.fields.push({
      key: 'actions',
      label: this.$t('backoffice.members.actions'),
      sortable: false,
    });
    
    // Resize
    window.addEventListener('resize', this.handleResize);
    this.handleResize();

    // If this space is a subspace... but we will only show orgs in a main
    // if (this.mainSpaceSlug) {
    //   console.log('This is a subspace, lets get the members in the main...')
    //   await this.getMainSpaceMembers();
    // }

    // Forms and answers
    this.answerRequest = this.requestedOptions[0];
    if (this.currentCommunity.hasForm) {
      this.getOnboardingForm();
    }

    // Completable elements
    this.getAllCommunityCompletableElements();
    this.getCommunityCompletes();
  },
  destroyed() {
    window.removeEventListener('resize', this.handleResize);
  },
  watch: {
    //
  },
  methods: {
    memberIsOwner(member) {
      return member?.roles?.filter((item) => item.name === 'Owner').length > 0;
    },
    getValidationDate() {
      return new Date(this.attendance[0]?.attendanceDate).toLocaleString();
    },
    async getAttendance(item) {
      this.isAttendanceLoading = true;
      await this.$store.dispatch('getItems', {
        itemType: 'communities/attendance/onsite',
        customName: 'attendance',
        page: 1,
        forceAPICall: true,
        requestConfig: {
          memberKey: item?.key,
        },
      });
      this.isAttendanceLoading = false;
    },
    handleResetPasswordTokenClipboardCopied() {
      this.isCopied = true;
      return navigator.clipboard.writeText(this.token.passwordToken);
    },
    handleRegisterTokenClipboardCopied() {
      this.isCopied = true;
      return navigator.clipboard.writeText(this.token.registerToken);
    },
    getModalityName(item) {
      if (item.modality === 'online') {
        return 'Online';
      }
      if (item.modality === 'on-site') {
        return this.$t('community-others.on-site');
      }
      return null;
    },
    async getCommunityCompletes() {
      await this.$store.dispatch('getItems', {
        itemType: 'completes',
        customName: 'completedItems',
        page: this.currentCompletesPage,
        forceAPICall: true,
        requestConfig: {
          withHierarchy: 1
        }
      });
      this.isLoading = false;
    },
    async getAllCommunityCompletableElements() {
      await this.$store.dispatch('getItems', {
        itemType: 'completable-elements',
        customName: 'completableElements',
        page: this.currentCompletableElementsPage,
        forceAPICall: true,
      });
      this.isLoading = false;
    },
    getProgress(item){
      const completableElements = this.totalCommunityCompletableElements;
      // console.log('getProgress completableElements', completableElements);
      const communityCompletes = this.communityCompletes;
      // console.log('getProgress communityCompletes', communityCompletes);

      //TODO... but will show an example to Episteme
      // const epistemeKey = '7c8951d0-4656-11ee-8ec0-91808168cb17';
      // const isEpisteme = this.collective.communityMain === epistemeKey || this.collective.key === epistemeKey;
      // if(!isEpisteme){
      //   return 0;
      // }
      // console.log('getProgress item', item);
      let completedNum = 0;
      communityCompletes.forEach(complete => {
        if(complete.completedByMember?.key === item.key){
          completedNum += 1;
        }
      });
      // console.log('getProgress completedNum', completedNum);
      return completedNum;
      //return Math.random() * completableElements;
    },
    getProgressColor(item){
      return 'info';
      // const completableElements = this.totalCommunityCompletableElements;
      // return item.completedNum ? (item.completedNum > 2 ? 'success' : 'danger') : 'info';
    },
    orgsFromMemberInMain(member) {
      // const mainMembers = await this.getMainSpaceMembers();
      // console.log('mainMembers', mainMembers);
      // console.log('this.mainSpaceMembers', this.mainSpaceMembers);
      // console.log('Estamos buscando las orgs del member en la main...');
      if (this.mainSpaceSlug && this.mainSpaceMembers.length > 0) {
        return this.mainSpaceMembers?.filter((i) => i.key === member.key)[0]?.organizations;
      }
      // if this is a main community, just get the current orgs
      // console.log('This is a main, getting current member orgs from member:', member);
      this.orgsFromCurrentMember = member.organizations;
      return member.organizations;
    },
    async getMainSpaceMembers() {
      const response = await this.$store.$service[Service.BackendClient].get('/communityMembers', {
        params: {
          communitySlug: this.mainSpaceSlug,
          isForBackoffice: 1,
        },
      });
      this.mainSpaceMembers = response.data.data;
      return response.data.data;
    },
    // Abrir Modal y hacer fetch a los amigos
    async selectMember(firstMember) {
      this.selectedMember = firstMember;
      this.openConnectionModal();
    },
    openConnectionModal() {
      this.isConnectionModalVisible = !this.isConnectionModalVisible;
    },
    handleCalendarMeetingOpen(member) {
      this.memberForMeeting = member;
      this.isRequestMeetingVisible = true;
    },
    handleSidebarChange(isVisible, user) {
      if (isVisible) {
        this.$store.dispatch(CHAT_ACTIONS.openIndividualChat, { user });
      } else {
        this.$store.dispatch(CHAT_ACTIONS.closeIndividualChat);
      }
    },
    getMemberLocation(user) {
      if (!user.username) {
        return null;
      }

      return {
        name: 'backoffice-members-details',
        params: { username: user.username, communityId: this.$route.params.communityId },
      };
    },
    handleResize() {
      this.isSmall = window.innerWidth < 400;
      this.isMedium = window.innerWidth < 1024;
    },
    getImageResource,
    getOrganizations(item) {
      return item?.organizations.map((organization) => organization.name);
    },
    getDate(item) {
      const d = new Date(item.updatedTime);
      return d.toLocaleString('en-GB');
    },
    getStatus(member) {
      return this.statusMember[member];
    },
    getColor(member) {
      const colorMember = {
        0: 'light',
        1: 'light-warning',
        2: 'warning',
        3: 'light-success',
        4: 'light-danger',
        5: 'light-dark',
        6: 'secondary',
        7: 'light-info',
        8: 'info',
        81: 'dark',
      };
      return colorMember[member];
    },
    addConnection(member1, member2) {
      const indexMember1 = this.members.findIndex((member) => member.key === member1);
      this.members[indexMember1].relationsCount++;
      const indexMember2 = this.members.findIndex((member) => member.key === member2);
      this.members[indexMember2].relationsCount++;
    },
    restConnection(member1, member2) {
      const indexMember1 = this.members.findIndex((member) => member.key === member1);
      this.members[indexMember1].relationsCount--;
      const indexMember2 = this.members.findIndex((member) => member.key === member2);
      this.members[indexMember2].relationsCount--;
    },
    handleOpenRequestedModal() {
      this.isRequestedModalOpened = true;
    },
    handleOpenInfoModal() {
      this.isInfoModalOpened = true;
    },
    handleOpenMemberModal(item) {
      this.isMemberModalOpened = true;
      this.enableStatus = this.$t('backoffice.members.confirmed');
      this.currentMember = item;
      this.isMember = this.currentMember.isMember;
    },
    handleOpenStatusModal() {
      this.isStatusModalOpened = true;
      this.isVerified = this.currentMember.status == 3; // Sometimes the status comes as a string
    },
    async toggleIsVerified() {
      this.isStatusModalOpened = false;
      this.isVerified = !this.isVerified;
      let member = '';
      try {
        member = { ...this.members.find((item) => item.key === this.currentMember.key) };
        if (
          this.$store.getters.currentCollective.key === '2d47c9f2-d12e-4bfd-859e-7b63fcc27efb'
          && member.status === 8
        ) {
          member.status = 81;
        } else {
          member.status = this.isVerified === true ? 3 : 4;
        }
        member.isMember = this.isVerified;
        await this.$store.dispatch('editItem', {
          item: {
            itemType: 'backoffice/memberStatus',
            customName: 'membersBO',
            requestConfig: {
              memberKey: this.currentMember.key,
              status: member.status,
            },
          },
        });
        member = this.members.find((item) => item.key === this.currentMember.key);
        if (
          this.$store.getters.currentCollective.key === '2d47c9f2-d12e-4bfd-859e-7b63fcc27efb'
          && member.status === 8
        ) {
          member.status = 81;
        } else {
          member.status = this.isVerified === true ? 3 : 4;
        }
        this.currentMember = {};
        this.notifySuccess(this.$t('backoffice.members.modals.success-status'));
      } catch {
        if (this.membersError !== '') {
          this.notifyError(`${this.$t('backoffice.settings.messages.capacity-members-error')}`);
        } else {
          this.notifyError(this.$t('backoffice.members.modals.error-status'));
        }
      }
    },
    async toggleIsMember() {
      this.isMemberModalOpened = false;
      this.isMember = !this.currentMember.isMember;
      // console.log('toggleIsMember this.isMember', this.isMember);
      let member = {};
      try {
        member = { ...this.members.find((item) => item.key === this.currentMember.key) };
        member.isMember = this.isMember;
        member.status = Object.keys(this.statusMember).find((key) => this.statusMember[key] === this.enableStatus);
        if (this.isMember) {
          await this.$store.dispatch('editItem', {
            item: {
              itemType: 'backoffice/memberStatus',
              customName: 'membersBO',
              requestConfig: {
                memberKey: this.currentMember.key,
                isMember: this.isMember,
                status: +member.status,
              },
            },
          });
        } else {
          await this.$store.dispatch('editItem', {
            item: {
              itemType: 'backoffice/toggleMember',
              customName: 'membersBO',
              requestConfig: {
                memberKey: this.currentMember.key,
                isMember: this.isMember,
                status: +member.status,
              },
            },
          });
        }

        member = this.members.find((item) => item.key === this.currentMember.key);
        member.isMember = this.isMember;
        member.status = Object.keys(this.statusMember).find((key) => this.statusMember[key] === this.enableStatus);
        this.currentMember = {};
        this.enableMember = '';
        this.notifySuccess(this.$t('backoffice.members.modals.success-member'));
      } catch {
        if (this.membersError !== '') {
          this.notifyError(`${this.$t('backoffice.settings.messages.capacity-members-error')}`);
        } else {
          this.notifyError(this.$t('backoffice.members.modals.error-member'));
        }
      }
    },
    handleOpenRemoveAllModal(item) {
      this.isRemoveAllModalOpened = true;
      this.currentMember = item;
    },
    async handleDisableAll() {
      this.isRemoveAllModalOpened = false;
      let member = {};
      try {
        member = { ...this.members.find((item) => item.key === this.currentMember.key) };
        member.isMember = false;
        await this.$store.dispatch('editItem', {
          item: {
            itemType: 'backoffice/disableMember',
            customName: 'membersBO',
            requestConfig: {
              memberKey: this.currentMember.key,
            },
          },
        });
        member = this.members.find((item) => item.key === this.currentMember.key);
        member.isMember = false;
        this.currentMember = {};
        this.notifySuccess(this.$t('backoffice.members.modals.success-desable-all'));
      } catch {
        this.notifySuccess(this.$t('backoffice.members.modals.error-disable-all'));
      }
    },
    handleOpenRemoveDefinitelyModal(item) {
      this.isRemoveDefinitelyModalOpened = true;
      this.currentMember = item;
    },
    async handleDisableDefinitely() {
      this.isRemoveDefinitelyModalOpened = false;
      try {
        this.members = this.members.filter((item) => item.key !== this.currentMember.key);
        await this.$store.dispatch('postItem', {
          type: '/deleteCommunityMember',
          requestConfig: {
            memberKey: this.currentMember.key,
          },
        });
        this.currentMember = {};
        this.notifySuccess(this.$t('organizations.backoffice.settings.members.alert-messages.success'));
      } catch {
        this.notifyError(this.$t('organizations.backoffice.settings.members.alert-messages.error'));
      }
    },
    handleStatusModals(item) {
      if (item.memberOfMain !== true && (item.status == 0)) {
        return this.handleOpenInfoModal();
      }
      this.currentMember = item;
      return this.currentMember.status == 1 ? this.handleOpenRequestedModal() : this.handleOpenStatusModal();
    },
    async sendAnswerRequested() {
      this.isRequestedModalOpened = false;
      this.isVerified = this.answerRequest === this.requestedOptions[0];
      let member = {};
      try {
        member = { ...this.members.find((item) => item.key === this.currentMember.key) };
        member.status = this.isVerified === true ? 3 : 4;
        member.isMember = this.isVerified;
        await this.$store.dispatch('editItem', {
          item: {
            itemType: 'backoffice/memberStatus',
            customName: 'membersBO',
            requestConfig: {
              memberKey: this.currentMember.key,
              status: member.status,
              firstResponse: true,
            },
          },
        });
        member = this.members.find((item) => item.key === this.currentMember.key);
        member.status = this.isVerified === true ? 3 : 4;
        member.isMember = this.isVerified;
        this.currentMember = {};
        this.notifySuccess(this.$t('backoffice.members.modals.success-status'));
      } catch {
        if (this.membersError !== '') {
          this.notifyError(`${this.$t('backoffice.settings.messages.capacity-members-error')}`);
        } else {
          this.notifyError(this.$t('backoffice.members.modals.error-status'));
        }
      }
    },
    handleOpenOnboardingModal(item) {
      this.currentMember = item;
      this.getOnboardingForm();
      this.isOnboardingModalOpened = true;
    },
    getOnboardingForm() {
      this.isLoading = true;
      try {
        this.$store.dispatch('getItems', {
          itemType: 'backoffice/onboarding/answers',
          customName: 'onboardingAnswers',
          forceAPICall: true,
          requestConfig: {
            communitySlug: this.$route.params.communityId,
            count: 1,
            memberKey: this.currentMember.key,
            //userKey: this.currentMember.userKey,
            languageCode: this.$store.getters.currentCollective.languageCode,
            showMemberInfo: true
          },
        });
      } catch {
        this.notifyError(this.$t('error-message.load-failed'));
      }
      this.isLoading = false;
    },
    async getRoles(page = 1) {
      const response = await this.$store.dispatch('getRoles', {
        page,
        perPage: 15,
        namespace: 'community',
      });
      this.communityRolesOptions = response.data.filter(({ namespace }) => namespace === 'community');
      this.backofficeRolesOptions = response.data.filter(({ namespace }) => namespace === 'backoffice');
    },
    async setRoles() {
      // Removed roles
      const removedRoles = [];
      this.currentMember.roles.forEach((item) => (!this.rolesSelected.includes(item) && !this.rolesBackofficeSelected.includes(item) ? removedRoles.push(item.key) : null));
      // Assigned Roles
      const keys = [];
      const allRoles = [...this.rolesBackofficeSelected, ...this.rolesSelected];
      this.communityRoles.map(({ key }) => {
        allRoles.map((item) => (key === item.key ? keys.push(key) : null));
      });
      try {
        await this.$store.dispatch('setRoles', {
          memberKey: this.currentMember.key,
          roleKeys: keys,
          deleteRoleKeys: removedRoles,
        });
        this.notifySuccess(this.$t('members.roles.messages.success'));
        this.$emit('roles', true);
      } catch {
        this.notifyError(this.$t('members.roles.messages.error'));
      }
    },
    roleEdit(item) {
      this.currentMember = item;
      this.rolesSelected = this.currentMember.roles.filter((item) => item.namespace === 'community');
      this.rolesBackofficeSelected = this.currentMember.roles.filter((item) => item.namespace === 'backoffice');

      this.isRolesModalVisible = true;
      this.getRoles();
    },
    async tokenEdit(item) {
      this.currentMember = item;
      await this.getEmailData();
      this.isTokenModalVisible = true;
    },
    async registerEmails(item) {
      this.currentMember = item;
      await this.getEmailData();
      this.isRegisterModalVisible = true;
    },
    async getEmailData() {
      try {
        const response = await this.$store.dispatch('getItems', {
          itemType: 'communityMember/emailData',
          customName: 'tokenVerification',
          forceAPICall: true,
          requestConfig: {
            memberKey: this.currentMember.key,
          },
        });
        this.token = response;
      } catch {}
    },
    async sendRegistrationEmail(type) {
      try {
        await this.$store.$service[Service.BackendClient].post('/backoffice/resendEmail', {
          communitySlug: this.currentCommunity.slug,
          typeOfEmail: type,
          memberKey: this.currentMember.key,
        });
        this.notifySuccess(this.$t(`share.messages.${type}.success`));
        this.getEmailData();
      } catch {
        this.notifyError(this.$t(`share.messages.${type}.error`));
      }
    },
    organizationLocation(organization) {
      if (this.$route.path.includes('backoffice')) {
        const route = this.$router.resolve({ name: 'Resumen', params: { communityId: organization.slug } });
        window.open(route.href, '_blank');
        return;
      }
      return this.$router.replace({
        name: 'organization',
        params: {
          slug: organization.slug,
          communityId: this.$route.params.communityId,
        },
      });
    },
    showAllOrganizations(item) {
      // this only when the user has more than 5 orgs
      this.currentMember = item;
      // this.orgsFromCurrentMember = this.orgsFromMemberInMain(item);
      this.isOrganizationsModalVisible = true;
    },
    async validateAttendance(item) {
      this.currentMember = item;
      try {
        await this.$store.dispatch('postItem', {
          type: '/communities/attendance/onsite',
          requestConfig: {
            memberKey: this.currentMember.key,
          },
        });
        this.notifySuccess(this.$t('backoffice.members.success'));
        await this.getAttendance(item);
        this.currentMember.attendOnsite = true;
      } catch {
        this.notifyError(this.$t('backoffice.members.messages.import-error'));
      }
    },
    handleUnattendance(item) {
      this.currentMember = item;
      this.isRemoveAttendanceModalOpened = true;
    },
    handleCertificate(item) {
      this.currentMember = item;
      this.isCertificateAttendanceModalOpened = true;
    },
    async handleRemoveAttendance() {
      try {
        await this.$store.dispatch('postItem', {
          type: '/backoffice/cancelOnsiteAttendance',
          requestConfig: {
            memberKey: this.currentMember.key,
          },
        });
        this.currentMember = {};
        this.$emit('roles', true);
        this.notifySuccess(this.$t('organizations.backoffice.settings.attendance.alert-messages.success'));
      } catch {
        this.notifyError(this.$t('organizations.backoffice.settings.attendance.alert-messages.error'));
      }
      this.isRemoveAttendanceModalOpened = false;
    },
    translateTranslationTable,
    getRoleName(role) {
      if(role.length > 1){
        // console.log('role', role);
      }
      return role[0]?.name ?? role.name;
    }
  },
};
</script>
<style lang="scss" scoped>
.truncated-text {
  white-space: nowrap;
  flex: 1;
  text-overflow: ellipsis;
  overflow: hidden;
  max-width: 250px;
  min-width: 0;
}
.check-button {
  cursor: pointer !important;
}
.uppercase {
  text-transform: uppercase;
}
</style>
