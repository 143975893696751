<template>
  <div>
    <!-- Buttons row -->
    <div
      class="mb-1"
      :class="{ 'd-flex justify-content-end flex-wrap mt-n1': !isSmall }"
    >
      <div :class="{ 'mt-n5': !isSmall }">
        <!-- Export members list -->
        <b-button
          v-if="members && members.length"
          :block="isSmall"
          variant="primary"
          class="mt-50"
          @click="isExportModalOpened = true"
        >
          <div v-if="!isLoadingButton">
            {{ $t("backoffice.members.export") }}
          </div>
          <b-spinner v-else small class="spinner" />
        </b-button>

        <!-- Invite members or staff button -->
        <b-button
          :block="isSmall"
          v-b-modal.new-member2
          variant="primary"
          class="mt-50"
          :class="{ 'ml-1': !isSmall }"
        >
          {{ $t("backoffice.members.invite") }}
        </b-button>
      </div>
    </div>

    <!-- Content -->
    <base-pagination-container
      class="members-list-container"
      :total="isLoading ? 0 : total"
      :loading="isLoading"
      :items="members"
      :per-page="perPage"
      :load-first-page="true"
      :search-placeholder="$t('members.search-placeholder')"
      searchable
      @load-page="handleLoadPage"
    >
      <!-- Filters button -->
      <template #search-actions="{}">
        <b-button
          variant="link"
          class="text-primary mb-1 p-0 toggle-filters-button"
          @click="toggleFilters()"
        >
          <b-icon-sliders class="mr-50" />
          {{ areFiltersVisible ? $t("") : $t("") }}
        </b-button>
      </template>

      <!-- Filters section -->
      <template #filters="{}">
        <div
          v-if="areFiltersVisible"
          class="flex-column flex-md-row justify-content-center filters-container mb-2 px-50 pt-1"
        >
          <!-- Classifiers -->
          <div
            class="d-flex flex-column flex-md-row filters-container mb-2 px-50 pt-1"
          >
            <b-form-group
              :label="$t('filterType')"
              label-for="classifier-filter"
              class="mr-1 w-100"
            >
              <v-select
                v-model="selectedClassifiersForFilter"
                name="classifier-filter"
                clearable
                label="name"
                :reduce="(a) => a"
                :options="Object.keys(classifiersPairs)"
                @input="setSeletedFilter"
              />
            </b-form-group>
            <!-- End Classifiers -->

            <!-- Custom fields -->
            <b-form-group
              v-if="nextFilter != null"
              label="Value"
              label-for="custom-filter"
              class="mr-1 w-100"
            >
              <v-select
                v-if="nextFilter[0] != null"
                v-model="selectedCustomFieldsForFilter"
                name="custom-filter"
                clearable
                label="name"
                :reduce="(nextFilter) => nextFilter"
                :options="nextFilter"
                @input="setSeletedFinalFilter"
              />

              <b-form-input
                v-if="nextFilter.type === 2"
                v-model="customFieldToSend[nextFilter.key]"
                placeholder="Start typing..."
                class="w-100"
                @keyup="handleChanger"
                @input="
                  setSeletedFinalFilter({
                    text: $event,
                    type: 2,
                    key: nextFilter.key,
                  })
                "
              />
            </b-form-group>
            <!-- End Custom fields -->
          </div>
        </div>
      </template>

      <!-- Users table -->
      <template #default="{ items, loading }">
        <app-scroll>
          <b-spinner
            v-if="isLoading"
            type="grow"
            small
            class="d-block mx-auto text-primary"
          />
          <div v-else>
            <!-- Staff table -->
            <staff-table
              v-if="type === 'backofficeStaff'"
              :members="items"
              :busy="loading"
              :search="shouldSearch"
              @roles="handleSetRoles"
            />
            <!-- Members table -->
            <members-table
              v-else
              :members="items"
              :busy="loading"
              :search="shouldSearch"
              @roles="handleSetRoles"
            />
          </div>
        </app-scroll>
      </template>
    </base-pagination-container>

    <!-- No members available -->
    <b-row v-if="showNoMembers" class="horizontal-placeholder">
      <b-col cols="12">
        <img :src="membersPlaceholder" />
      </b-col>
      <b-col cols="12">
        <p class="text-primary">
          {{ $t("available.message", { itemName: $t("members.title") }) }}
        </p>
      </b-col>
    </b-row>

    <!-- Invite staff modal -->
    <invite-staff-modal 
      v-if="type === 'backofficeStaff'"
      @invited="handleChanger"
    />
    <!-- Invite members modal -->
    <invite-members-modal 
      v-else
      @invited="handleChanger" 
    />

    <!-- Loading... -->
    <loading-modal
      v-model="isLoadingButton"
      :is-upload="isUpload"
      v-ripple.400="'rgba(234, 84, 85, 0.15)'"
    />

    <!-- Export modal -->
    <export-modal v-model="isExportModalOpened" @export="exportCsv" />
  </div>
</template>

<script>
import BasePaginationContainer from "@core/components/containers/base/BasePaginationContainer.vue";
import CardWidgetMixin from "@core/mixins/widgets/CardWidgetMixin";
import AppScroll from "@/@core/components/app-scroll/AppScroll.vue";
import ToastNotificationsMixin from "@core/mixins/toast-notifications/ToastNotificationsMixin";
import vSelect from "vue-select";
import { translateTranslationTable } from "@/@core/libs/i18n/utils";
import MembersTable from "@/backoffice/views/members/components/MembersTable.vue";
import StaffTable from "@/backoffice/views/members/components/StaffTable.vue";
import BackofficeContainer from "@/backoffice/components/BackofficeContainer.vue";
import { validatorEmailValidator } from "@/@core/utils/validations/validators";
import FileUpload from "@core/components/files/FileUpload.vue";
import LoadingModal from "@core/components/modal/LoadingModal.vue";
import ExportModal from "@core/components/modal/ExportModal.vue";
import Ripple from "vue-ripple-directive";
import Placeholder from "@/assets/images/placeholders/light/general-placeholder.svg";
import { quillEditor } from "vue-quill-editor";
import ErrorModal from "@core/components/modal/ErrorModal.vue";
import InviteStaffModal from './InviteStaffModal.vue';
import InviteMembersModal from './InviteMembersModal.vue';
import MembersPlaceholder from "@/assets/images/placeholders/light/members.svg";

export default {
  name: "MembersContent",
  components: {
    BasePaginationContainer,
    AppScroll,
    MembersTable,
    StaffTable,
    vSelect,
    BackofficeContainer,
    FileUpload,
    LoadingModal,
    ExportModal,
    quillEditor,
    ErrorModal,
    InviteStaffModal,
    InviteMembersModal,
  },
  mixins: [CardWidgetMixin, ToastNotificationsMixin],
  directives: {
    Ripple,
  },
  props: {
    perPage: {
      type: Number,
      default: 15,
    },
    organizationKey: {
      type: String,
      default: null,
    },
    type: {
      type: String,
      default: "communityMembers",
    },
  },
  data() {
    return {
      invited: {
        email: "",
        member: true,
        staff: false,
        notification: true,
        modality: "",
        bypassForm: false,
        bypassPlans: false,
      },
      emails: [],
      classifiersPairs: "",
      isValidArray: [],
      isLoading: false,
      filtersIndex: 0,
      filters: [],
      parentClassifiers: [],
      timeoutID: null,
      areFiltersVisible: false,
      allClassifierFilters: [],
      nextFilter: [],
      selectedClassifiersForFilter: [],
      finalSelectClassifier: [],
      selectClassifierParent: [],
      selectedCustomFieldsForFilter: [],
      index: 0,
      actualData: [],
      customFieldsInputs: [],
      customFieldToSend: {},
      isLoadingClassifiers: true,
      isValid: null,
      classifiers: [],
      csvFile: null,
      uploadFileVisible: false,
      page: 1,
      shouldSearch: false,
      isExportModalOpened: false,
      isUpload: false,
      isLoadingButton: false,
      isSmall: false,
      csvTemplateEmails: [
        ["Name", "Surname", "email"],
        ["Name1", "Surname1", "email1@example.com"],
        ["Name2", "Surname2", "email2@example.com"],
      ],
    };
  },
  computed: {
    membersPlaceholder() {
      return MembersPlaceholder;
    },
    placeholder() {
      return Placeholder;
    },
    formIsVisible() {
      if (this.currentCommunity.isEvent) {
        if (this.currentCommunity.modality === "online") {
          return this.canShowOnline;
        }
        if (this.currentCommunity.modality === "on-site") {
          return this.canShowOnsite;
        }
        if (this.currentCommunity.modality === "hybrid") {
          return this.canShowOnline || this.canShowOnsite;
        }
      }
      return true;
    },
    modalityOptions() {
      let options = [
        { code: 0, modality: this.$t("events.event-details.online") },
        { code: 1, modality: this.$t("events.event-details.on-site") },
      ];
      if (!this.canShowOnline) {
        options.splice(0, 1);
        if (!this.canShowOnsite) {
          options.splice(0, 1);
        }
      } else if (!this.canShowOnsite) {
        options.splice(1, 1);
      }
      return options;
    },
    file() {
      return this.$store.getters.file;
    },
    members() {
      // console.log('members() this.type', this.type);
      // console.log('members() this.page', this.page);
      // if(!this.type){
      //   this.type = 'communityMembers';
      // }
      // console.log('this.$store.getters.communityMembers', this.$store.getters.communityMembers)

      const storeMembers = this.$store.getters.communityMembers?.[this.type]?.paginated[this.page];
      // console.log('storeMembers', storeMembers);
      if(!storeMembers){

      }
      return this.filterMembersByNamespace(storeMembers);
    },
    total() {
      if(!this.type){
        this.type = 'communityMembers';
      }
      return (this.$store.getters.communityMembers?.[this.type]?.meta?.total ?? 0);
    },
    classifiersOptions() {
      const classifierName = [];
      for (const row of this.classifiers) {
        classifierName.push({
          name: translateTranslationTable(
            this.$store.state.locale.currentLocale,
            row.name
          ),
          key: row.key,
        });
      }
      return classifierName;
    },
    customFields() {
      return this.$store.getters.customFields ?? [];
    },
    customFieldsOptions() {
      const customName = [];
      try {
        for (const row of this.customFields) {
          customName.push({
            name: translateTranslationTable(
              this.$store.state.locale.currentLocale,
              row.name
            ),
            key: row.key,
            type: 2,
          });
        }
        return customName;
      } catch (e) {
        return customName;
      }
    },
    sortedCommunityClassifiers() {
      const newObject = {};
      for (const item of this.classifiers) {
        if (
          !newObject[
            translateTranslationTable(
              this.$store.state.locale.currentLocale,
              item.typeName
            )
          ]
        ) {
          newObject[
            translateTranslationTable(
              this.$store.state.locale.currentLocale,
              item.typeName
            )
          ] = [item];
        } else {
          newObject[
            translateTranslationTable(
              this.$store.state.locale.currentLocale,
              item.typeName
            )
          ].push(item);
        }
      }
      const keys = Object.keys(newObject);
      const finalKeys = [];
      for (const row of keys) {
        finalKeys.push({
          key: row,
          name: row,
          type: 1,
        });
      }
      return [newObject, finalKeys];
    },
    classifiersTypeOptions() {
      return this.sortedCommunityClassifiers;
    },
    currentLocale() {
      return this.$store.state.locale.currentLocale;
    },
    currentCommunity() {
      return this.$store.getters.currentCollective;
    },
    canShowOnline() {
      return (
        this.currentCommunity.occupation?.online == null ||
        this.currentCommunity?.onlineOccupation <
          this.currentCommunity.occupation?.online ||
        !this.currentCommunity.onlineOccupation
      );
    },
    canShowOnsite() {
      return (
        this.currentCommunity.occupation == null ||
        this.currentCommunity.occupation["on-site"] == null ||
        this.currentCommunity?.onsiteOccupation <
          this.currentCommunity?.occupation["on-site"] ||
        !this.currentCommunity?.onsiteOccupation
      );
    },
    showNoMembers() {
      return false;
      //return !this.members || this.members?.length === 0;
    }
  },
  watch: {
    members(value) {
      this.isLoading = false;
      return this.filterMembersByNamespace(value);
    },
    async type(oldValue, newValue) {
      // console.log('watch type oldValue', oldValue);
      // console.log('watch type newValue', newValue);
      if (oldValue !== newValue) {
        // console.log('handleLoadPage from watch type');
        await this.handleLoadPage({ page: 1, search: "", isFilterOn: false });
      }
    },
  },
  async created() {
      if(!this.type){
        this.type = 'communityMembers';
      }
    this.fetchClassifiers();
    this.fetchCustomfields();
    // this.fetchMembers();
    // console.log('handleLoadPage from created');
    // this.handleLoadPage({ page: 1, search: "", isFilterOn: true });
    if (
      this.currentCommunity.isEvent &&
      this.currentCommunity.modality === "hybrid"
    ) {
      this.invited.modality =
        this.canShowOnline || this.canShowOnsite
          ? this.modalityOptions[0].code
          : "";
    }
    if (
      this.currentCommunity.isEvent &&
      this.currentCommunity.modality === "on-site"
    ) {
      this.invited.modality = this.modalityOptions[1].code;
    }
    if (
      this.currentCommunity.isEvent &&
      this.currentCommunity.modality === "online"
    ) {
      this.invited.modality = this.modalityOptions[0].code;
    }
    window.addEventListener("resize", this.handleResize);
    this.handleResize();
  },
  destroyed() {
    window.removeEventListener("resize", this.handleResize);
  },
  methods: {
    handleResize() {
      this.isSmall = window.innerWidth < 680;
    },
    // actualInputData(data) {
    //   this.actualData = data;
    //   const dat = [];
    //   for (const row of data) {
    //     dat.push({
    //       name: translateTranslationTable(
    //         this.$store.state.locale.currentLocale,
    //         row.name
    //       ),
    //       key: row.key,
    //     });
    //   }
    //   return dat;
    // },
    // newFilter(option, index) {
    //   this.filters[index] = option;
    //   this.filtersIndex++;
    //   this.filtersIndex--;
    // },
    // setSelectedParentClassifier(option) {
    //   this.parentClassifiers.push(option);
    // },
    // getSortedClassifiers(option) {
    //   return this.sortedCommunityClassifiers[option];
    // },
    async handleLoadPage({ page, search, isFilterOn = false }) {
      // console.log('Loading new page of members...')
      this.isLoading = true;
      this.page = page;
      // console.log('search', search);
      if (!search && !isFilterOn) {
        console.log('MembersContent handleLoadPage no search no filter...');
        try {
          // console.log('this.$route.params.communityId', this.$route.params.communityId);
          await this.$store.dispatch("getItems", {
            itemType: this.type ?? 'communityMembers',
            customName: "communityMembers",
            storedKey: this.type ?? 'communityMembers',
            forceAPICall: true,
            requestConfig: {
              communitySlug: this.$route.params.communityId,
              ...(this.finalSelectClassifier && this.finalSelectClassifier.key
                ? { classifiers: [this.finalSelectClassifier.key] }
                : ""),
              page,
              perPage: this.perPage,
              isForBackoffice: 1,
            },
          });
          this.$emit("total-updated", this.total);
        } catch {
          this.notifyError(this.$t("error-message.load-failed"));
        }
        this.isLoading = false;
      } else {
        if (this.timeoutID) {
          clearTimeout(this.timeoutID);
        }
        console.log('MembersContent handleLoadPage with search or filter...');
        this.timeoutID = setTimeout(async () => {
          this.shouldSearch = true;
          try {
          // console.log('this.$store.getters.currentCollective.slug', this.$store.getters.currentCollective.slug);
            await this.$store.dispatch("getItems", {
              itemType: this.type ?? 'communityMembers',
              customName: 'communityMembers',
              forceAPICall: true,
              storedKey: this.type ?? 'communityMembers',
              requestConfig: {
                communitySlug: this.$store.getters.currentCollective.slug,
                ...(this.finalSelectClassifier && this.finalSelectClassifier.key
                  ? { classifiers: [this.finalSelectClassifier.key] }
                  : ""),
                page,
                perPage: this.perPage,
                searchEmail: search,
                searchString: search,
                isForBackoffice: 1,
              },
            });
            this.$emit("total-updated", this.total);
          } catch {
            this.notifyError(this.$t("error-message.load-failed"));
          }
          this.timeoutID = null;
          this.isLoading = false;
        }, 500);
      }
    },
    downloadCsv() {
      let csv = "";
      this.csvTemplateEmails.forEach((row) => {
        csv += row;
        csv += "\r\n";
      });
      const hiddenElement = document.createElement("a");
      hiddenElement.href = `data:text/csv;charset=utf-8,${encodeURI(csv)}`;
      hiddenElement.target = "_blank";
      hiddenElement.download = "CSV_template_members.csv";
      hiddenElement.click();
    },
    async exportCsv(item) {
      this.isLoadingButton = true;
      const response = await this.$store.dispatch("exportFiles", {
        itemType: "backoffice/onboarding/exportAnswers",
        requestConfig: {
          ...(item ? { mainTypeIDs: item } : ""),
        },
      });
      setTimeout(this.csv(response), 10000);
      this.isLoadingButton = false;
    },
    csv(response) {
      const hiddenElement = document.createElement("a");
      hiddenElement.href = response.data.file;
      hiddenElement.target = "_blank";
      hiddenElement.download = "Members.csv";
      hiddenElement.click();
    },
    addCustomField() {
      this.customFieldsInputs.push({ id: this.index });
      this.index++;
    },

    addFilters() {
      this.filtersIndex++;
    },

    handleChangeCustomInput(value) {},

    async fetchClassifiers() {
      await this.$store.dispatch("getItems", {
        itemType: "classifiers",
        storedKey: "members",
        page: 1,
        requestConfig: {
          morphType: "members",
          count: 1000,
        },
      });
      this.classifiers = this.$store.getters.classifiers.members.unpaginated;
      this.classifiersPair();
    },

    async fetchCustomfields() {
      await this.$store.dispatch("fetchCustomFields", {
        communityKey: this.$store.getters.currentCollective.key,
        morphType: "member",
      });
    },

    handleChanger() {
      // console.log('handleLoadPage from handleChanger');
      this.handleLoadPage({ page: 1, search: "", isFilterOn: true });
    },

    toggleFilters() {
      this.areFiltersVisible = !this.areFiltersVisible;
      if (!this.areFiltersVisible) {
        (this.total = 0),
          (this.filtersIndex = 0),
          (this.filters = []),
          (this.parentClassifiers = []),
          (this.selectedClassifiersForFilter = []);
        this.customFieldsInputs = [];
        this.index = 0;
        // console.log('handleLoadPage from toggleFilters');
        this.handleLoadPage({ page: 1, search: "", isFilterOn: false });
      }
    },
    async setSeletedFilter(selected) {
      this.CustomFieldToSend = {};
      this.finalSelectClassifier = null;
      this.selectedCustomFieldsForFilter = null;
      this.nextFilter = null;
      if (!selected) {
        this.finalSelectClassifier = null;
        // console.log('handleLoadPage from setSeletedFilter');
        this.handleLoadPage({ page: 1, search: "", isFilterOn: false });
      } else {
        let translated = [];
        if (this.classifiers) {
          this.classifiers.map((item) => {
            if (
              translateTranslationTable(
                this.$store.state.locale.currentLocale,
                item.typeName
              ) === selected
            ) {
              translated.push({
                name: translateTranslationTable(
                  this.$store.state.locale.currentLocale,
                  item.name
                ),
                key: item.key,
                type: 1,
              });
            }
          });
        } else if (selected.type === 2) {
          this.customFieldToSend[selected.key] = "";
          translated = { type: 2, key: selected.key };
        }
        this.nextFilter = translated;
      }
    },
    async setSelectClassifierParent(selected) {
      this.allClassifierFilters = selected;
        // console.log('handleLoadPage from setSelectClassifierParent');
      this.handleLoadPage({ page: 1, search: "", isFilterOn: true });
    },
    async setSeletedFinalFilter(selected) {
      this.finalSelectClassifier = selected;
      if (selected) {
        // console.log('handleLoadPage from setSeletedFinalFilter');
        this.handleLoadPage();
      }
    },
    classifiersPair() {
      const itemClassifiers = {};
      this.classifiers.filter((classifier) => {
        if (
          itemClassifiers[
            translateTranslationTable(
              this.$store.state.locale.currentLocale,
              classifier.typeName
            )
          ]
        ) {
          itemClassifiers[
            translateTranslationTable(
              this.$store.state.locale.currentLocale,
              classifier.typeName
            )
          ].push(
            translateTranslationTable(
              this.$store.state.locale.currentLocale,
              classifier.name
            )
          );
        } else {
          itemClassifiers[
            translateTranslationTable(
              this.$store.state.locale.currentLocale,
              classifier.typeName
            )
          ] = [
            translateTranslationTable(
              this.$store.state.locale.currentLocale,
              classifier.name
            ),
          ];
        }
      });
      this.classifiersPairs = itemClassifiers;
    },
    validator(item) {
      this.isValid = validatorEmailValidator(item) && item !== "";
    },
    validatorArray(item, i) {
      this.isValidArray[i] = validatorEmailValidator(item) && item !== "";
    },
    async inviteMember() {
      this.isLoading = true;
      if (this.csvFile !== null) {
        const msg = {};
        msg[this.currentLocale] = this.invited.message;
        this.isLoadingButton = true;
        this.isUpload = true;
        try {
          await this.$store.dispatch("importFiles", {
            importType: "backoffice/members/import",
            file: this.csvFile,
            forceUpdate: true,
            customName: "communityMembers",
            storedKey: this.$store.getters.currentCollective.slug,
            listingType: "",
            requestConfig: {
              isMember: this.invited.member,
              isStaff: this.invited.staff,
              customMsg: msg,
              sendMail: this.invited.notification,
              ...(this.currentCommunity.isEvent
                ? { modality: this.invited.modality }
                : ""),
              bypassForm: this.invited.bypassForm,
              bypassPlans: this.invited.bypassPlans,
            },
          });
          this.isLoadingButton = false;
          this.isUpload = false;
          this.notifySuccess(
            this.$t("backoffice.members.messages.import-success")
          );
        } catch (e) {
          console.log('inviteMember error:', e);
          this.notifyError(this.$t("backoffice.members.messages.import-error"));
        }
        this.uploadFileVisible = false;
      }
      this.handleInvite();
      this.isLoading = false;
      this.isValid = null;
      this.reset();
    },
    async handleInvite() {
      const allValid = this.isValidArray.every(
        (item) => item === true || item === null
      );
      if (this.isValid && allValid) {
        this.emails.push({
          email: this.invited.email,
          name: this.invited.name,
          surname: this.invited.surname,
          isMember: this.invited.member,
          isStaff: this.invited.staff,
          customMssg: this.invited.message,
          sendMail: this.invited.notification,
          ...(this.currentCommunity.isEvent
            ? { modality: this.invited.modality }
            : ""),
          bypassForm: this.invited.bypassForm,
          bypassPlans: this.invited.bypassPlans,
        });
        const emails = this.emails.map(({ customMssg, ...rest }) => {
          const msg = {};
          msg[this.currentLocale] = customMssg;
          const customMsg = msg;
          return { customMsg, ...rest };
        });
        this.isValid = null;
        this.isValidArray = [];
        try {
          const response = await this.$store.dispatch("inviteMembers", {
            emails: emails,
          });
          if (response.status === 200) {
            this.notifySuccess(this.$t("backoffice.members.messages.success"));
            this.handleLoadPage({ page: 1, search: "", isFilterOn: true });
          } else if (response.status === 403) {
            this.$bvModal.show("error-modal");
          }
          this.emails = [];
          this.reset();
        } catch (error) {
          this.notifyError(this.$t("backoffice.members.messages.error"));
          this.reset();
        }
      }
    },
    addEmail() {
      if (this.isValid) {
        this.emails.push({
          email: this.invited.email,
          name: this.invited.name,
          surname: this.invited.surname,
          isMember: this.invited.member,
          isStaff: this.invited.staff,
          customMssg: this.invited.message,
          sendMail: this.invited.notification,
          ...(this.currentCommunity.isEvent
            ? { modality: this.invited.modality }
            : ""),
          bypassForm: this.invited.bypassForm,
          bypassPlans: this.invited.bypassPlans,
        });
        this.invited.email = "";
        this.invited.name = "";
        this.invited.surname = "";
        this.invited.member = true;
        this.invited.staff = false;
        (this.invited.bypassPlans = false),
          (this.invited.bypassForm = false),
          (this.invited.notification = true);
        this.invited.message = "";
        this.isValid = null;
        if (
          this.currentCommunity.isEvent &&
          this.currentCommunity.modality === "hybrid"
        ) {
          this.invited.modality = this.modalityOptions[0].code;
        }
      }
    },
    reset() {
      this.invited.email = "";
      this.invited.name = "";
      this.invited.surname = "";
      this.invited.member = true;
      this.invited.staff = false;
      (this.invited.bypassPlans = false),
        (this.invited.bypassForm = false),
        (this.invited.notification = true);
      this.invited.message = "";
      this.emails = [];
      this.isValidArray = [];
      this.csvFile = null;
      this.uploadFileVisible = false;
      if (
        this.currentCommunity.isEvent &&
        this.currentCommunity.modality === "hybrid"
      ) {
        this.invited.modality = this.modalityOptions[0].code;
      }
    },
    async handleSetRoles(response) {
      if (response === true) {
        await this.handleLoadPage({
          page: this.page,
          search: "",
          isFilterOn: false,
        });
      }
    },
    translateTranslationTable,
    filterMembersByNamespace(members){
      // console.log('filterMembersByNamespace input:', members);
      if(!members){
        // console.log('filterMembersByNamespace - No members provided, return empty array.')
        return [];
      }
      const output = members.filter(member => {
        // console.log('member.isMember', member.isMember);
        const isCommunityMember = this.type === 'communityMembers' && member.isMember;
        // console.log('isCommunityMember', isCommunityMember);
        if(isCommunityMember){
          return member;
        }
        // console.log('member.isStaff', member.isStaff);
        const isBackofficeStaff = this.type === 'backofficeStaff' && member.isStaff;
        // console.log('isBackofficeStaff', isBackofficeStaff);
        if(isBackofficeStaff){
          return member;
        }
      });
      // console.log('return members after filtering, output:', output);
      return output;
    }
  },
};
</script>

<style lang="scss" scoped>
.members-list-container {
  overflow: hidden;
  flex: 1;
}
.toggle-filters-button {
  outline: none;
  box-shadow: none;
  min-width: 25px;
  margin-left: 10px;
}
</style>
