<template>
    <b-modal
      id="new-member2"
      :title="$t('backoffice.members.invite-staff')"
      :ok-title="$t('form.actions.save')"
      size="lg"
      ok-only
      :hide-footer="!formIsVisible"
      :ok-disabled="!isValid && csvFile === null"
      @ok="inviteStaff()"
      @hidden="reset()"
    >
      <div v-if="formIsVisible">
        <!-- Email -->
        <b-form-group class="flex-grow-1">
          <label for="email-name" class="font-weight-bold">
            {{ $t("backoffice.settings.email-label") }}*</label
          >
          <b-form-input
            id="email-name"
            v-model="invited.email"
            type="email"
            :placeholder="$t('form.type-placeholder')"
            :state="isValid"
            @input="emailValidator(invited.email)"
          />
        </b-form-group>
        <b-row>
          <!-- Name -->
          <b-col>
            <b-form-group class="flex-grow-1">
            <label for="invited-name" class="font-weight-bold">
                {{ $t("backoffice.roles.name") }}</label
            >
            <b-form-input
                id="invited-name"
                v-model="invited.name"
                type="text"
                :placeholder="$t('form.type-placeholder')"
            />
            </b-form-group>
          </b-col>
          <!-- Surname -->
          <b-col>
            <b-form-group class="flex-grow-1">
            <label for="invited-surname" class="font-weight-bold">
                {{ $t("backoffice.owner.surname") }}</label
            >
            <b-form-input
                id="invited-surname"
                v-model="invited.surname"
                type="text"
                :placeholder="$t('form.type-placeholder')"
            />
            </b-form-group>
          </b-col>
        </b-row>

        <!-- Email invitation -->
        <label for="email-name" class="font-weight-bold mt-50">
          {{ $t("backoffice.settings.invitation-email") }}</label
        >
        <b-form-group class="mb-0">
          <div class="d-flex mt-25">
            <b-form-checkbox switch v-model="invited.notification" />
            <p>{{ $t("posts.post-creation.action.email-notification") }}</p>
          </div>
        </b-form-group>
        <b-form-group v-if="invited.notification">
          {{ $t("backoffice.settings.email-message") }}
          <quill-editor
            id="message"
            v-model="invited.message"
            class="mt-1"
            name=" "
            :placeholder="$t('products.contact.modal.message-placeholder')"
            rows="8"
          />
        </b-form-group>

        <b-form-group v-for="(email, i) in emails" :key="i" class="flex-grow-1">
          <hr />
          <b-row>
            <b-col>
              <b-form-group class="flex-grow-1">
                <label for="invited-name" class="font-weight-bold">
                  {{ $t("backoffice.roles.name") }}</label
                >
                <b-form-input
                  id="invited-name"
                  v-model="emails[i].name"
                  type="text"
                  :placeholder="$t('form.type-placeholder')"
                />
              </b-form-group>
            </b-col>
            <b-col>
              <b-form-group class="flex-grow-1">
                <label for="invited-surname" class="font-weight-bold">
                  {{ $t("backoffice.owner.surname") }}</label
                >
                <b-form-input
                  id="invited-surname"
                  v-model="emails[i].surname"
                  type="text"
                  :placeholder="$t('form.type-placeholder')"
                />
              </b-form-group>
            </b-col>
          </b-row>
          <label for="email-name" class="font-weight-bold">
            {{ $t("backoffice.settings.email-label") }}*</label
          >
          <b-form-input
            id="email-name"
            v-model="emails[i].email"
            type="email"
            :placeholder="$t('form.type-placeholder')"
            :state="isValidArray[i]"
            @input="validatorEmailsArray(emails[i].email, i)"
          />
          <!-- Email invitation -->
          <label for="email-name" class="font-weight-bold mt-50">
            {{ $t("backoffice.settings.invitation-email") }}</label
          >
          <div class="d-flex mt-25">
            <b-form-checkbox switch v-model="emails[i].sendMail" />
            <p>{{ $t("posts.post-creation.action.email-notification") }}</p>
          </div>
          <b-form-group v-if="emails[i].sendMail">
            {{ $t("backoffice.settings.email-message") }}
            <quill-editor
              id="message"
              class="mt-1"
              v-model="emails[i].customMssg"
              name=" "
              :placeholder="$t('products.contact.modal.message-placeholder')"
              rows="8"
            />
          </b-form-group>
        </b-form-group>

        <b-button
          variant="link"
          :disabled="!isValid"
          class="p-0 pointer font-weight-bold"
          @click="addEmail()"
        >
          {{ $t("backoffice.members.add-member") }}
        </b-button>
        <br />
        <div
          class="d-flex flex-wrap align-items-center justify-content-between mt-1"
        >
          <b-button
            variant="link"
            class="p-0 mt-50 pointer font-weight-bold"
            @click="uploadFileVisible = !uploadFileVisible"
          >
            {{ $t("backoffice.members.modals.import-members") }}
          </b-button>
          <b-badge
            variant="light-primary"
            class="ml-25 mt-50 font-weight-normal pointer"
            @click="downloadCsv()"
            ><feather-icon icon="DownloadIcon" class="mr-25" />{{
              $t("download.csv")
            }}</b-badge
          >
        </div>
        <file-upload
          v-if="uploadFileVisible"
          v-model="csvFile"
          type="csv"
          class="w-100 mt-1"
          cancelable
          :placeholder="$t('media.form.file.placeholder')"
          :drop-placeholder="$t('media.form.file.drop-placeholder')"
        />
      </div>
      <!-- Invite staff not available -->
      <b-row v-else class="horizontal-placeholder flex-column">
        <b-col cols="12">
          <feather-icon
            icon="UserXIcon"
            size="40"
            class="m-0 d-block mx-auto text-danger mb-2"
          />
        </b-col>
        <b-col cols="12">
          <p class="text-danger">
            {{ $t("events.details.completed-capacity") }}
          </p>
        </b-col>
      </b-row>
    </b-modal>
</template>


<script>
import vSelect from "vue-select";
import { translateTranslationTable } from "@/@core/libs/i18n/utils";
// import { validatorEmailValidator } from "@/@core/utils/validations/validators";
import FileUpload from "@core/components/files/FileUpload.vue";
import ExportModal from "@core/components/modal/ExportModal.vue";
// import Ripple from "vue-ripple-directive";
import Placeholder from "@/assets/images/placeholders/light/general-placeholder.svg";
import { quillEditor } from "vue-quill-editor";
import ToastNotificationsMixin from '@core/mixins/toast-notifications/ToastNotificationsMixin';

export default {
  name: "InviteStaffModal",
  components: {
    vSelect,
    FileUpload,
    ExportModal,
    quillEditor,
  },
  mixins: [ToastNotificationsMixin],
  props: {
  },
  data() {
    return {
      invited: {
        email: "",
        member: true,
        staff: false,
        notification: true,
      },
      emails: [],
      classifiersPairs: "",
      isValidArray: [],
      isLoading: false,
      filtersIndex: 0,
      filters: [],
      parentClassifiers: [],
      timeoutID: null,
      areFiltersVisible: false,
      allClassifierFilters: [],
      nextFilter: [],
      selectedClassifiersForFilter: [],
      finalSelectClassifier: [],
      selectClassifierParent: [],
      selectedCustomFieldsForFilter: [],
      index: 0,
      actualData: [],
      customFieldsInputs: [],
      customFieldToSend: {},
      isLoadingClassifiers: true,
      isValid: null,
      classifiers: [],
      csvFile: null,
      uploadFileVisible: false,
      page: 1,
      search: false,
      isExportModalOpened: false,
      isUpload: false,
      isLoadingButton: false,
      isSmall: false,
      csvTemplateEmails: [
        ["Name", "Surname", "email"],
        ["Name1", "Surname1", "email1@example.com"],
        ["Name2", "Surname2", "email2@example.com"],
      ],
    };
  },
  computed: {
    placeholder() {
      return Placeholder;
    },
    formIsVisible() {
      return true;
    },
    file() {
      return this.$store.getters.file;
    },
    members() {
      console.log('this.type', this.type);
      console.log('this.page', this.page);
      return (this.$store.getters.communityMembers?.[this.type]?.paginated[this.page] || []);
    },
    total() {
      return (this.$store.getters.communityMembers?.[this.type]?.meta?.total ?? 0);
    },
    currentLocale() {
      return this.$store.state.locale.currentLocale;
    },
    currentCommunity() {
      return this.$store.getters.currentCollective;
    },
  },
  watch: {
    members(value) {
      console.log(value);
      this.isLoading = false;
      return value;
    },
    async type(oldValue, newValue) {
      console.log(oldValue);
      console.log(newValue);
      if (oldValue !== newValue) {
        await this.handleLoadPage({ page: 1, search: "", isFilterOn: false });
      }
    },
  },
  async created() {
    window.addEventListener("resize", this.handleResize);
    this.handleResize();
  },
  destroyed() {
    window.removeEventListener("resize", this.handleResize);
  },
  methods: {
    handleResize() {
      this.isSmall = window.innerWidth < 680;
    },
    downloadCsv() {
      let csv = "";
      this.csvTemplateEmails.forEach((row) => {
        csv += row;
        csv += "\r\n";
      });
      const hiddenElement = document.createElement("a");
      hiddenElement.href = `data:text/csv;charset=utf-8,${encodeURI(csv)}`;
      hiddenElement.target = "_blank";
      hiddenElement.download = "CSV_template_members.csv";
      hiddenElement.click();
    },
    async exportCsv(item) {
      this.isLoadingButton = true;
      const response = await this.$store.dispatch("exportFiles", {
        itemType: "backoffice/onboarding/exportAnswers",
        requestConfig: {
          ...(item ? { mainTypeIDs: item } : ""),
        },
      });
      setTimeout(this.csv(response), 10000);
      this.isLoadingButton = false;
    },
    csv(response) {
      const hiddenElement = document.createElement("a");
      hiddenElement.href = response.data.file;
      hiddenElement.target = "_blank";
      hiddenElement.download = "Staffs.csv";
      hiddenElement.click();
    },
    emailValidator(item) {
      this.isValid = this.validateEmail(item);
    },
    validatorEmailsArray(item, i) {
      this.isValidArray[i] = this.validateEmail(item);
    },
    validateEmail(email) {
      // console.log('emailValidator input', email);
      return true;

      //TODO: the regex validator in the next function does not accept ".barcelona" as a valid domain because it's too long
      // return this.validatorEmailValidator(email) && email !== "";
    },
    async inviteStaff() {
      this.isLoading = true;
      if (this.csvFile !== null) {
        const msg = {};
        msg[this.currentLocale] = this.invited.message;
        this.isLoadingButton = true;
        this.isUpload = true;
        try {
          await this.$store.dispatch("importFiles", {
            importType: "backoffice/members/import",
            file: this.csvFile,
            forceUpdate: true,
            customName: "communityMembers",
            storedKey: this.$store.getters.currentCollective.slug,
            listingType: "",
            requestConfig: {
              isMember: false,
              isStaff: true,
              customMsg: msg,
              sendMail: this.invited.notification,
            },
          });
          this.isLoadingButton = false;
          this.isUpload = false;
          this.notifySuccess(
            this.$t("backoffice.members.messages.import-success")
          );
        } catch (e) {
          console.log(e);
          this.notifyError(this.$t("backoffice.members.messages.import-error"));
        }
        this.uploadFileVisible = false;
      }
      this.handleInvite();
      this.isLoading = false;
      this.isValid = null;
      this.reset();
    },
    async handleInvite() {
      const allValid = this.isValidArray.every(
        (item) => item === true || item === null
      );
      if (this.isValid && allValid) {
        this.emails.push({
          email: this.invited.email,
          name: this.invited.name,
          surname: this.invited.surname,
          isMember: false,
          isStaff: true,
          customMssg: this.invited.message,
          sendMail: this.invited.notification,
        });
        const emails = this.emails.map(({ customMssg, ...rest }) => {
          const msg = {};
          msg[this.currentLocale] = customMssg;
          const customMsg = msg;
          return { customMsg, ...rest };
        });
        this.isValid = null;
        this.isValidArray = [];
        try {
          const response = await this.$store.dispatch("inviteMembers", {
            emails: emails,
          });
          if (response.status === 200) {
            this.notifySuccess(this.$t("backoffice.members.messages.success"));
            this.$emit('invited', 'staff');
          } else if (response.status === 403) {
            this.$bvModal.show("error-modal");
          }
          this.emails = [];
          this.reset();
        } catch (error) {
          this.notifyError(this.$t("backoffice.members.messages.error"));
          this.reset();
        }
      }
    },
    addEmail() {
      if (this.isValid) {
        this.emails.push({
          email: this.invited.email,
          name: this.invited.name,
          surname: this.invited.surname,
          isMember: false,
          isStaff: true,
          customMssg: this.invited.message,
          sendMail: this.invited.notification,
        });
        this.invited.email = "";
        this.invited.name = "";
        this.invited.surname = "";
        this.invited.member = false;
        this.invited.staff = true;
        this.invited.notification = true;
        this.invited.message = "";
        this.isValid = null;
      }
    },
    reset() {
      this.invited.email = "";
      this.invited.name = "";
      this.invited.surname = "";
      this.invited.member = false;
      this.invited.staff = true;
      this.invited.notification = true;
      this.invited.message = "";
      this.emails = [];
      this.isValidArray = [];
      this.csvFile = null;
      this.uploadFileVisible = false;
    },
    async handleSetRoles(response) {
      if (response === true) {
        await this.handleLoadPage({
          page: this.page,
          search: "",
          isFilterOn: false,
        });
      }
    },
    translateTranslationTable,
  },
};
</script>